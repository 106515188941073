import React, {Fragment} from 'react';
import {Fade} from 'react-reveal';
import SectionTitle from './SectionTitle'
import {Link} from 'react-router-dom';
import {scrollToTop} from '../API/utils'
import ReactMarkdown from 'react-markdown';

const Services = ({services}) => {
  let probing = null;
  let scanning = null;
  let reverse = null;
  let machine = null;
  let printing = null;
  let afterSales = null;
  
  if (services && services.length) {
    probing = services.filter(service => service.fields.slug === "probing-inspection")[0];
    scanning = services.filter(service => service.fields.slug === "scanning-inspection")[0];
    reverse = services.filter(service => service.fields.slug === "reverse-engineering")[0];
    machine = services.filter(service => service.fields.slug === "machine-rental")[0];
    printing = services.filter(service => service.fields.slug === "3d-printing")[0];
    afterSales = services.filter(service => service.fields.slug === "after-sales-services")[0];
  }

  return (services && <Fragment>
  <Fade><SectionTitle title='Our Services' /></Fade>
      <Fade><div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start', textAlign:'left', color:'grey', fontSize:'16px', flexDirection:`${window.innerWidth > 480 ? 'row' : 'column'}`}}>
      {probing && <Link onClick={scrollToTop} style={{width:`${window.innerWidth > 480 ? '30%' : '100%'}`}} to='/services/probing-inspection'>
        <div style={{padding:'20px 10px', display:'flex', justifyContent:'center', boxSizing:'border-box', width:'100%'}}>
          <div style={{color:'#777', fontSize:'30px', padding:'20px'}}>
            <img style={{width:'50px', height:'50px'}} src={probing.fields.logo.fields.file.url} alt="probing"/>
          </div>
          <div>
            <h2 style={{color:'#777', margin:'0', fontSize:'16px'}}>{probing.fields.title}</h2>
            {probing && probing.fields && probing.fields.description && probing.fields.description.content && probing.fields.description.content.length && <div>
                {
                  probing.fields.description.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
          </div>
        </div>
      </Link>}
      {scanning && <Link onClick={scrollToTop} style={{width:`${window.innerWidth > 480 ? '30%' : '100%'}`}} to='/services/scanning-inspection'>
        <div style={{padding:'20px 10px', display:'flex', justifyContent:'center', boxSizing:'border-box', width:'100%'}}>
          <div style={{color:'#777', fontSize:'30px', padding:'20px'}}>
            <img style={{width:'50px', height:'50px'}} src={scanning.fields.logo.fields.file.url} alt="scanning"/>
          </div>
          <div>
            <h2 style={{color:'#777', margin:'0', fontSize:'16px'}}>{scanning.fields.title}</h2>
            {scanning && scanning.fields && scanning.fields.description && scanning.fields.description.content && scanning.fields.description.content.length && <div>
                {
                  scanning.fields.description.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
          </div>
        </div>
      </Link>}
      {reverse && <Link onClick={scrollToTop} style={{width:`${window.innerWidth > 480 ? '30%' : '100%'}`}} to='/services/reverse-engineering'>
        <div style={{padding:'20px 10px', display:'flex', justifyContent:'center', boxSizing:'border-box', width:'100%'}}>
          <div style={{color:'#777', fontSize:'30px', padding:'20px'}}>
            <img style={{width:'50px', height:'50px'}} src={reverse.fields.logo.fields.file.url} alt="reverse engineering"/>
          </div>
          <div>
            <h2 style={{color:'#777', margin:'0', fontSize:'16px'}}>{reverse.fields.title}</h2>
            {reverse && reverse.fields && reverse.fields.description && reverse.fields.description.content && reverse.fields.description.content.length && <div>
                {
                  reverse.fields.description.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}            
          </div>
        </div>
      </Link>}
      </div>
      </Fade>
      <Fade>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start', textAlign:'left', color:'grey', fontSize:'16px', flexDirection:`${window.innerWidth > 480 ? 'row' : 'column'}`}}>
      {machine && <Link onClick={scrollToTop} style={{width:`${window.innerWidth > 480 ? '30%' : '100%'}`}} to='/services/machine-rental'>
        <div style={{padding:'20px 10px', display:'flex', justifyContent:'center', boxSizing:'border-box', width:'100%'}}>
          <div style={{color:'#777', fontSize:'30px', padding:'20px'}}>
          <img style={{width:'50px', height:'50px'}} src={machine.fields.logo.fields.file.url} alt="machine-rental"/>
          </div>
          <div>
            <h2 style={{color:'#777', margin:'0', fontSize:'16px'}}>{machine.fields.title}</h2>
            {machine && machine.fields && machine.fields.description && machine.fields.description.content && machine.fields.description.content.length && <div>
                {
                  machine.fields.description.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}   
          </div>
        </div>
      </Link>}
      {printing && <Link onClick={scrollToTop} style={{width:`${window.innerWidth > 480 ? '30%' : '100%'}`}} to='/services/3d-printing'>
        <div style={{padding:'20px 10px', display:'flex', justifyContent:'center', boxSizing:'border-box', width:'100%'}}>
          <div style={{color:'#777', fontSize:'30px', padding:'20px'}}>
            <img style={{width:'50px', height:'50px'}} src={printing.fields.logo.fields.file.url} alt="3d printing"/>
          </div>
          <div>
            <h2 style={{color:'#777', margin:'0', fontSize:'16px'}}>{printing.fields.title}</h2>
            {printing && printing.fields && printing.fields.description && printing.fields.description.content && printing.fields.description.content.length && <div>
                {
                  printing.fields.description.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}  
          </div>
        </div>
      </Link>}
      {afterSales && <Link onClick={scrollToTop} style={{width:`${window.innerWidth > 480 ? '30%' : '100%'}`}} to='/services/after-services'>
        <div style={{padding:'20px 10px', display:'flex', justifyContent:'center', boxSizing:'border-box', width:'100%'}}>
          <div style={{color:'#777', fontSize:'30px', padding:'20px'}}>
            <img style={{width:'50px', height:'50px'}} src={afterSales.fields.logo.fields.file.url} alt="after services"/>
          </div>
          <div>
            <h2 style={{color:'#777', margin:'0', fontSize:'16px'}}>{afterSales.fields.title}</h2>
            {afterSales && afterSales.fields && afterSales.fields.description && afterSales.fields.description.content && afterSales.fields.description.content.length && <div>
                {
                  afterSales.fields.description.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
          </div>
        </div>
      </Link>}
      </div>
      </Fade>
</Fragment>)}

export default Services