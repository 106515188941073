import React from 'react'
import { Helmet } from "react-helmet";
import smsImage from '../assets/sms-pt.jpg'

const Metadata = ({ image, description, title }) => {
  let _image = image || smsImage;
  let _description = description || 'A highly dedicated precision metrology instrument dealer based in Indonesia. Authorized Distributor of Carl Zeiss, FARO and GEOMagic.';
  let _title = title ? title + ' | PT Smart Mitra Solutions (SMS)' : 'PT Smart Mitra Solutions (SMS)'
  let _url = window.location.href;
  let _sitename = 'https://sms-sg.com';

  return (
    <Helmet defer={false}>
      <title>{_title}</title>
      <meta name="description" content={_description} />
      <meta name="title" content={_title} />
      <meta property="og:title" content={_title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={_url} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image" content={_image} />
      <meta property="og:site_name" content={_sitename} />
      <meta property="og:description" content={_description} />
      <meta name="fb:admins" content="99999999" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={_sitename} />
      <meta name="twitter:image" content={_image} />
      <meta name="twitter:title" content={_title} />
      <meta name="twitter:description" content={_description} />
      <meta name="twitter:text:title" content={_title} />
      <meta name="twitter:text:description" content={_description} />
    </Helmet>
  )
}

export default Metadata
