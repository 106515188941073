import styled, {keyframes} from 'styled-components';

const foldingCube = keyframes`
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  } 
`


export const SpinnerCubeContainer = styled.div`
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg); 
`

export const Cube1 = styled.div`
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1); 

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #24326D;
    -webkit-animation: ${foldingCube} 2.4s infinite linear both;
            animation: ${foldingCube} 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
`
export const Cube2 = styled(Cube1)`
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
  &:before {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
`

export const Cube3 = styled(Cube1)`
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
  &:before {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s; 
  }
`
export const Cube4 = styled(Cube1)`
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
  &:before {
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
  }
`
