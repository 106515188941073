import * as contentful from 'contentful';
require("babel-register")({
  presets: ["es2015", "react", "babel-preset-stage-0"]
});

const API_SPACE_ID = 'bt9ow552vkcn' || process.env.REACT_APP_CONTENTFUL_API_SPACE_ID;
const API_TOKEN = 'URbEN1NCRcJeNfS34CZQc6t7j_yj12ZK8GqVY48RdI4' || process.env.REACT_APP_CONTENTFUL_API_TOKEN;
const CONTENTFUL_HOMEPAGE_ENTRY_ID = '2a2shYvtgBPA7wQ7eqh102' || process.env.REACT_APP_CONTENTFUL_HOMEPAGE_ENTRY_ID;
const client = contentful.createClient({
  space: API_SPACE_ID,
  accessToken: API_TOKEN
})

export const getContentfulAssets = async (assetId) => {
  const data = await client.getAsset(assetId)
  return 'https:' + data.fields.file.url;
} 

export const getSocialMedia = async () => {
  const data = await client.getEntries({
    content_type: 'socialMediaList',
  })
  // console.log('getCategoriesProduct', data)
  return data.items && data.items[0].fields && data.items[0].fields.socialMediaList;
}

export const getCategoriesProduct = async (slug) => {
  const data = await client.getEntries({
    content_type: 'categoriesOfProduct',
    'fields.slug[in]': slug
  })
  // console.log('getCategoriesProduct', data)
  const brand = {
    name: data.items[0].fields.brand.fields.title,
    slug: data.items[0].fields.brand.fields.slug
  }
  const intro = data.items[0].fields.intro && data.items[0].fields.intro.content[0] && data.items[0].fields.intro.content[0].content[0] && data.items[0].fields.intro.content[0].content[0].value;
  const products = data.items[0].fields.products;
  const title = data.items && data.items.length && data.items[0].fields.title;
  return {products, title, brand, intro};
}

export const getEntry = async (id) => {
  try {
    const data = await client.getEntry(id);
    return data;
  } catch(e) {
    console.error(e);
  }
}

export const getAllBrandsData = async () => {
  const _brandsData = await client.getEntries({
    content_type: 'brand',
  });
  if (_brandsData) {
    let data = _brandsData.items.map(({fields}) => {
      let landingPageMobileImages = [] 
      if(fields.landingPageMobileImages) {
        landingPageMobileImages = fields.landingPageMobileImages.map(images => images);
      }
      if (fields.products) {
        return {...fields, products: fields.products.map(product => product.fields), landingPageMobileImages}
      } else {
        return {...fields, landingPageMobileImages}
      }
    });
    
    data = data.sort((a, b) => (a.brandPriority > b.brandPriority) ? 1 : -1)
    data = await Promise.all(data.map(async brand => ({...brand,
          categoriesOfBrandsProducts: await Promise.all(brand.categoriesOfBrandsProducts.map(async categories => {
            if (categories.fields.childCategories && categories.fields.childCategories.length) {
              {
                const childCategories = await Promise.all(categories.fields.childCategories.map(async category => await getEntry(category.sys.id)));
                return {...categories,
                  childCategories
                }
              }
            } else {
              return categories
            }
          }))
        })))
    return data;
  } else {
    return false;
  }
}

export const getContactUs = async () => {
  try {
    const _contactUs = await client.getEntries({
      content_type: 'contactUs'
    });
    return _contactUs.items[0]
  } catch(e) {
    console.error(e);
  }
}

export const getCompanyListingService = async () => {
  try {
    const _contactUs = await client.getEntries({
      content_type: 'companyListingService'
    });
    return _contactUs;
  } catch(e) {
    console.error(e);
  }
}

export const getAfterSalesServices = async () => {
  try {
    const _contactUs = await client.getEntries({
      content_type: 'afterSalesServices'
    });
    return _contactUs;
  } catch(e) {
    console.error(e);
  }
}

export const getAboutUs = async () => {
  try {
    const _contactUs = await client.getEntries({
      content_type: 'aboutUs'
    });
    return _contactUs.items[0]
  } catch(e) {
    console.error(e);
  }
}

export const getNewsDetail = async (slug) => {
  try {
    const _brandsData = await client.getEntries({
      content_type: 'article',
      'fields.slug[in]': slug,
    });  
    return {
      items: _brandsData.items.map(item => {
        return({
          title: item.fields.title,
          slug: item.fields.slug,
          source: item.fields.source,
          thumbnail: item.fields.thumbnail.fields.file.url,
          banner: item.fields.mainImage.fields.file.url,
          publishDate: item.fields.publishDate,
          content: item.fields.content.content
        })
      }),
    }  } catch(e) {
    console.error(e)
  }
}

export const getNewsData = async (offset=0, limit=3) => {
  console.log('[offset]', offset)
  let skip = offset !== 0 ? (offset - 1) * limit : offset * limit;
  // let skip = offset !== 0 ? offset * limit : offset;
  try {
    const _brandsData = await client.getEntries({
      content_type: 'article',
      limit: limit,
      skip: skip,
      order: '-fields.publishDate'
    });  
    return {
      items: _brandsData.items.map(item => {
        return({
          title: item.fields.title,
          slug: item.fields.slug,
          thumbnail: item.fields.thumbnail.fields.file.url,
          banner: item.fields.mainImage.fields.file.url,
          publishDate: item.fields.publishDate,
          content: item.fields.content.content[0].content[0].value
        })
      }),
      total: _brandsData.total
    }
  } catch(e) {
    console.error(e);
  }
}

export const getBrandsData = async (slug) => {
  const _brandsData = await client.getEntries({
    content_type: 'brand',
    'fields.slug[in]': slug
  });
  // console.log('[_brandsData]', _brandsData)
}

export const getHomePageData = async () => {
  const _homePage = await client.getEntries({
    'sys.id': CONTENTFUL_HOMEPAGE_ENTRY_ID
  });
  if (_homePage) {
    const data = _homePage.items[0].fields;
    const bannerDesktop = data.bannerDesktop;
    const bannerMobile = data.bannerMobile;
    const subHeading = data.subHeading;
    const mainHeading = data.mainHeading.content[0].content[0].value;
    // const mainHeading = <ReactMarkdown className='readable-text' escapeHtml={false} skipHtml={false} source={data.mainHeading.content[0].content[0].value} />;
    
    let fetchedBannerDesktop = bannerDesktop.map(({fields}) => fields.file.url)
    let fetchedBannerMobile = bannerMobile.map(({fields}) => fields.file.url)
    return {
      mainHeading,
      subHeading,
      bannerDesktop: fetchedBannerDesktop,
      bannerMobile: fetchedBannerMobile
    }
  } else {
    return false;
  }
}