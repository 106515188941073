import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom'
import {MainHeading, SubHeading, HeadingContainer} from '../components/HeadingTitle'
import {Fade} from 'react-reveal';
import {MainContainer} from '../theme/style'
import Breadcrumb from '../components/Breadcrumb'
import {AppConsumer} from '../API/context'
import {scrollToTop} from '../API/utils'
import ReactMarkdown from 'react-markdown';
import Metadata from '../components/Metadata'

const truncate_text = function(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

const ProductBrands = ({image, description, slug}) => <Fade delay={100}>  <Link onClick={scrollToTop} style={{textDecoration:'none'}} to={`products/${slug}`}><div key={slug} className='brands-card'>
  <div style={{width:'40%'}}><img className='img-logo-landing-brands' src={image} alt={`logo ${slug}`}/></div>
  <div style={{width:'60%', display:'flex', flexDirection:'column', alignItems:'flex-start', color:'#777', lineHeight:'1.5', display:'flex', justifyContent:'center', textAlign:'left', padding:'10px', boxSizing:'border-box'}}>
    <p>{description}</p>
    <span style={{textDecoration:'underline'}}>Learn More</span>
  </div>
</div></Link>
</Fade>

const ProductBrandsMobile = ({image, description, slug}) => <Fade delay={100}>  <Link onClick={scrollToTop} style={{textDecoration:'none'}} to={`products/${slug}`}><div key={slug} className='brands-card-mobile'>
  <div style={{width:'100%'}}><img style={{margin:'-20%', width:'75%'}} className='img-logo-landing-brands' src={image} alt={`logo ${slug}`}/></div>
  <div style={{width:'100%', borderBottom:'1px solid gainsboro', display:'flex', flexDirection:'column', alignItems:'flex-start', color:'#777', lineHeight:'1.5', display:'flex', justifyContent:'center', textAlign:'left', padding:'10px', boxSizing:'border-box', marginBottom:'50px'}}>
    <p>{description}</p>
    <span style={{textDecoration:'underline', fontSize:`${window.innerWidth < 480 ? '14px' : '16px'}`}}>Learn More</span>
  </div>
</div></Link>
</Fade>

class BrandsCollection extends Component {
  
  render() {
    const renderProductsBrands = <AppConsumer>
      {
        ({brandsData}) => {
          //let brands = brandsData.reverse();
          let brands = brandsData;
          let transformedBrands = brands.map(({slug, brandImage, description}) => <ProductBrands slug={slug} image={brandImage.fields && brandImage.fields.file && brandImage.fields.file.url} description={<ReactMarkdown className='readable-text' escapeHtml={false} skipHtml={false} source={description.content[0].content[0].value} />} />)
          if (window.innerWidth <= 480) {
            transformedBrands = brands.map(({slug, brandImage, description}) => <ProductBrandsMobile slug={slug} image={brandImage.fields && brandImage.fields.file && brandImage.fields.file.url} description={<ReactMarkdown className='readable-text' escapeHtml={false} skipHtml={false} source={truncate_text(description.content[0].content[0].value, 250)} />} />)
          }
          return transformedBrands;
        }        
      }
    </AppConsumer>
    
    return(
      <MainContainer className='brands-listing-container' style={{padding:'30px 10px'}}>
        <Metadata title='Products' description='We are the authorized distributor of the leading brands' />
        <Breadcrumb breadcrumbs={['products']} />
        <div style={{padding:'30px 0'}}>
        <HeadingContainer>
          <SubHeading>Our Products</SubHeading>
          <MainHeading smaller>We are <span style={{color:'#777', fontWeight:'bold'}}>the authorized distributor of the leading brands</span> in the area of metrology, testing and 3D scanning for Indonesia market.</MainHeading>
        </HeadingContainer>
        </div>
        {renderProductsBrands}
      </MainContainer>
    );
  }
}

export default BrandsCollection;