import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {Fade} from 'react-reveal';
import {MainContainer} from '../theme/style'

class NotFound extends Component {
  componentWillMount() {
    this.props.history.replace('/')
  }
  
  render() {
    return (<MainContainer style={{padding:'30px 0'}}>
      <Fade delay='100'>
        <h1 style={{maxWidth:'700px', margin:'0', color:'grey', fontWeight:'normal', fontSize:'40px'}}>Oops, the requested Url, back To Home!</h1>
      </Fade>
    </MainContainer>)
  }
}

export default withRouter(NotFound);