import React, {Component, Fragment} from 'react';
import {withRouter, Link} from 'react-router-dom'
import ReactMarkdown from 'react-markdown';
import {MainContainer} from '../theme/style'
import {MainHeading, SubHeading, HeadingContainer} from '../components/HeadingTitle'
import SectionTitle from '../components/SectionTitle'
import {scrollToTop} from '../API/utils'
import Breadcrumb from '../components/Breadcrumb'
import Slider from 'react-slick'
import {Fade} from 'react-reveal';
import Metadata from '../components/Metadata'

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
class BrandLandingPage extends Component {
  state = {
    brand: null,
    slug: null
  }
  
  componentDidMount() {
    this.init();
  }

  init = () => {
    if(!this.props.match.params.query) {
      this.props.history.replace('/')
    } else {
      if (this.props.context.brandsData) {
        const brand = this.props.context.brandsData.filter(brand => brand.slug === this.props.match.params.query)[0]
        if (brand) {
          this.setState({brand, slug: brand.title})
        } else {
          // this.props.history.replace('/');
        }
      }
    }
  } 
  
  handleGoToProductDetail = (slug) => {
    this.props.history.push(this.props.location.pathname + '/' + slug);
  }

  _renderBannerBreadCrumb = () => {
    const {brand} = this.state;
    if (brand && brand.title) {
      return <Breadcrumb breadcrumbs={['products', brand.title]} />
    }
  }
  
  _renderBrandSlogan = () => {
    const {brand} = this.state;
    if(brand) {
      return <Fragment>
              <Metadata title={brand.title} description={this.state.brand.slogan ? this.state.brand.slogan.content[0].content[0].value : false} />
              <HeadingContainer>
                <MainHeading>
                  <ReactMarkdown className='readable-text centered-text' escapeHtml={false} skipHtml={false} source={this.state.brand.slogan ? this.state.brand.slogan.content[0].content[0].value : 'slogan placeholder'} />
                </MainHeading>
            </HeadingContainer>
        </Fragment> 
    }   
  }
  
  _renderBrandSubSlogan = () => {
    const {brand} = this.state;
    if(brand) {
      return <HeadingContainer>
          <SubHeading>
            <ReactMarkdown className='readable-text centered-text' escapeHtml={false} skipHtml={false} source={this.state.brand.subSlogan  && this.state.brand.subSlogan.content && this.state.brand.subSlogan.content[0] && this.state.brand.subSlogan.content[0].content[0] && this.state.brand.subSlogan.content[0].content[0].value ? this.state.brand.subSlogan.content[0].content[0].value : 'slogan placeholder'} />
          </SubHeading>
        </HeadingContainer>
    }   
  }

  _renderBrandBanner = () => {
    const {brand} = this.state;
    const {windowWidth} = this.props.context;
    if (brand && brand.landingPageImages) {
      return <Fade delay='100'><div style={{marginTop:'30px', marginBottom:'20px'}}>
          {
            windowWidth > 480 ?
            <Fragment>
              {brand && brand.landingPageImages && brand.landingPageImages.length > 1 ?
                <Slider {...settings}>
                  {brand.landingPageImages.map(({fields}) => <div><img style={{width:'100%'}} src={fields.file.url} alt={brand.title}/></div>)}
                </Slider>
              :
              <img style={{width:'100%'}} src={brand.landingPageImages[0].fields.file.url} alt=""/>
              }
            </Fragment>
            :
            <Fragment>
              {brand && brand.landingPageMobileImages && brand.landingPageMobileImages.length > 1 ?
                <Slider {...settings}>
                  {brand.landingPageMobileImages.map(({fields}) => <div><img style={{width:'100%'}} src={fields.file.url} alt={brand.title}/></div>)}
                </Slider>
                :
                <img style={{width:'100%'}} src={brand.landingPageMobileImages[0].fields.file.url} alt=""/>
              }
            </Fragment>            
          }
        </div></Fade>
    }
  }

  _renderBrandCategories = () => {
    const {brand} = this.state;
    const {windowWidth} = this.props.context;
    const perChunk = windowWidth > 1366 ? 3 : windowWidth > 480 ? 3 : 2;
    if (brand && brand.categoriesOfBrandsProducts && brand.categoriesOfBrandsProducts.length) {
      const categories = brand.categoriesOfBrandsProducts;
      const transformedCategories = categories.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/perChunk)
  
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
  
        resultArray[chunkIndex].push(item)
  
        return resultArray
      }, []);   
      return <Fragment>
        {transformedCategories.map(categories => <div style={{margin: '30px auto', marginTop:`${window.innerWidth > 480 ? '30px' : '0'}`, width:'99%', display:'flex', justifyContent:`${categories.length === perChunk ? window.innerWidth <= 480 ? 'space-around' : 'space-between' : 'space-around'}`}}>
      <Fragment>
        {
          categories.map((category, index) => <Fade delay={index * 50}>
            {
              category && category.fields && category.fields.childCategories && category.fields.childCategories.length ?
              <div style={{width:`${windowWidth > 1366 ? '33%' : windowWidth > 480 ? '30%' : '40%'}`}}>
              <Link onClick={scrollToTop} to={`/collection/${brand.slug}/${category.fields.slug}`}><div style={{backgroundImage:`url(${category.fields && category.fields.thumbnail && category.fields.thumbnail.fields && category.fields.thumbnail.fields.file && category.fields.thumbnail.fields.file.url})`, height:`${windowWidth > 1366 ? '200px' : '133px'}`, backgroundPosition:'center', backgroundSize:'contain', backgroundRepeat:'no-repeat'}}></div>
              <div style={{backgroundColor:'white', padding:`${window.innerWidth <=480 ? '10px' : '20px'}`, boxSizing:'border-box', textTransform: 'uppercase', fontSize:`${window.innerWidth > 480 ? '18px' : '14px'}`}}>
                <span>{category.fields.title}</span>
              </div></Link> 
            </div>        
              :
              
              category && category.fields && category.fields.products && category.fields.products.length ?
              <div style={{width:`${windowWidth > 1366 ? '33%' : windowWidth > 480 ? '30%' : '40%'}`, cursor:'pointer'}}>
              <Link onClick={scrollToTop} to={`/products/${brand.slug}/${category.fields.slug}`}><div style={{backgroundImage:`url(${category.fields && category.fields.thumbnail && category.fields.thumbnail.fields && category.fields.thumbnail.fields.file && category.fields.thumbnail.fields.file.url})`, height:`${windowWidth > 1366 ? '200px' : '133px'}`, backgroundPosition:'center', backgroundSize:'contain', backgroundRepeat:'no-repeat'}}></div>
              <div style={{backgroundColor:'white', padding:`${window.innerWidth <=480 ? '10px' : '20px'}`, boxSizing:'border-box', textTransform: 'uppercase', fontSize:`${window.innerWidth > 480 ? '18px' : '14px'}`}}>
                <span>{category.fields.title}</span>
              </div></Link> 
            </div>
              :
              <div style={{width:`${windowWidth > 1366 ? '33%' : windowWidth > 480 ? '30%' : '40%'}`}}>
              <div style={{backgroundImage:`url(${category.fields && category.fields.thumbnail && category.fields.thumbnail.fields && category.fields.thumbnail.fields.file && category.fields.thumbnail.fields.file.url})`, height:`${windowWidth > 1366 ? '200px' : '133px'}`, backgroundPosition:'center', backgroundSize:'contain', backgroundRepeat:'no-repeat'}}></div>
              <div style={{backgroundColor:'white', padding:`${window.innerWidth <=480 ? '10px' : '20px'}`, boxSizing:'border-box', textTransform: 'uppercase', fontSize:`${window.innerWidth > 480 ? '18px' : '14px'}`}}>
                <span>{category.fields && category.fields.title}</span>
              </div>
            </div>        
            }
</Fade>)
        }
      </Fragment>
    </div>)}
      </Fragment>

    }
  }

  render() {
    const {brand} = this.state;
    const renderBreadCrumb = this._renderBannerBreadCrumb();
    const renderBrandSlogan = this._renderBrandSlogan();
    const renderBrandBanner = this._renderBrandBanner();
    const renderBrandCategories = this._renderBrandCategories();
    const renderBrandSubSlogan = this._renderBrandSubSlogan();

    return(
      <MainContainer style={{padding:'30px 10px'}}>
        {renderBreadCrumb}
        <div style={{padding:'30px 0'}}>
          {renderBrandSlogan}
          {renderBrandBanner}
          {renderBrandSubSlogan}
        </div>
        {brand && brand.title && <SectionTitle title={brand.title + ' Products Lineup'}/>}
        {renderBrandCategories}
      </MainContainer>
    );
  }
}

export default withRouter(BrandLandingPage);
