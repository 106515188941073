import React, {Component} from 'react';
import {MainContainer} from '../theme/style'
import {MainHeading, SubHeading, HeadingContainer} from '../components/HeadingTitle'
import SectionTitle from '../components/SectionTitle'
import {Fade} from 'react-reveal';
import Breadcrumb from '../components/Breadcrumb'
import {getAboutUs} from '../API/contentful'
import ReactMarkdown from 'react-markdown';
import SMSPhoto from '../assets/sms-pt.jpg'
import ourVision from '../assets/our-vision.png'
import ourServices from '../assets/our-services.png'
// import ourValues from '../assets/our-values.png'
import ourMission from '../assets/our-mission.png'
import calibration from '../assets/calibration-showroom.png'
import facility from '../assets/facility-calibration.png'
import ourValues from '../assets/our-values.jpg'
import Metadata from '../components/Metadata'

class AboutUs extends Component {
  state = {
    companyMotto: null,
    companyName: null,
    companyDescription: [],
    banner: null,
    descriptionCompanyCalibrationLab: [],
    descriptionCompanyMission: [],
    descriptionCompanyServices: [],
    descriptionCompanyValues: [],
    descriptionCompanyVision: [],
    descriptionFacilityShowroom: [],
    imageCompanyCalibrationLab: null,
    imageCompanyMission: null,
    imageCompanyServices: null,
    imageCompanyValues: null,
    imageCompanyVision: null,
    imageFacilityShowroom: null,
  }

  async componentDidMount() {
    const _aboutUs = await getAboutUs();
    if (_aboutUs) {
      console.log('_aboutUs', _aboutUs)
      const {fields} = _aboutUs;
      this.setState({
        companyMotto: fields.companyMotto,
        companyName: fields.companyName,
        companyDescription: fields.companyDescription.content,
        banner: fields.banner.fields.file.url,
        descriptionCompanyCalibrationLab: fields.descriptionCompanyCalibrationLab.content,
        descriptionCompanyMission: fields.descriptionCompanyMission.content,
        descriptionCompanyServices: fields.descriptionCompanyServices.content,
        descriptionCompanyValue: fields.descriptionCompanyValue.content,
        descriptionCompanyVision: fields.descriptionCompanyVision.content,
        descriptionFacilityShowroom: fields.descriptionFacilityShowroom.content,
        imageCompanyCalibrationLab: fields.imageCompanyCalibrationLab.fields.file.url,
        imageCompanyMission: fields.imageCompanyMission.fields.file.url,
        imageCompanyServices: fields.imageCompanyServices.fields.file.url,
        imageCompanyValues: fields.imageCompanyValues.fields.file.url,
        imageCompanyVision: fields.imageCompanyVision.fields.file.url,
        imageFacilityShowroom: fields.imageFacilityShowroom.fields.file.url,
      }, () => console.log(this.state))
    }
  };
  
  
  _renderAboutUsHeading = () => {
      return <div style={{margin:'0 0 30px 0'}}>
      {this.state.companyName && <h1 style={{margin: 0, fontWeight:'normal'}}>{this.state.companyName}</h1>}
      {this.state.companyMotto && <SubHeading>{this.state.companyMotto}</SubHeading>}
        </div>
  }
  
  render() {
    const renderAboutUsHeading = this._renderAboutUsHeading();

    return(
      <MainContainer style={{padding:'30px 10px', color:'grey', overflow: 'inherit'}}>
        <Metadata description={`PT Smart Mitra Solutions, a highly dedicated precision metrology equipment supplier, offers its most excellent service by providing the solution of the best equipment to support your manufacturing needs.
              Located in Cikarang, SMS has built and equipped its show room with the latest finest technology of demonstration machine and software to support its customer needs of getting a reliable metrology solutions.
              Our team of qualified product sales specialists, applications engineers, and administrative support, have been committed in providing the highest level of customer satisfaction, applications and service support to the manufacturing businesses to achieve outstanding results <b>"The Right Partner for the Best Solution"</b> We believe that our rich experience provides evidence of a sincere, long-term commitment as a right partner to both our customers and our suppliers. Being the partner of the world's leaders of precision metrology company, we continuously providing the best solution for multi-dimensional metrology in the metrology lab and production floor.`} 
              title='About Us'/>
        <Breadcrumb breadcrumbs={['about us']} />
          {this.state.banner && <div class='about-us-image' style={{backgroundImage: `url(${this.state.banner})`}}></div>}
        <div className="about-us-container">
          <div className="about-us-info">
            <Fade delay={100}>
            {renderAboutUsHeading}
            <p style={{fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>
              PT Smart Mitra Solutions, a highly dedicated precision metrology equipment supplier, offers its most excellent service by providing the solution of the best equipment to support your manufacturing needs.
              Located in Cikarang, SMS has built and equipped its show room with the latest finest technology of demonstration machine and software to support its customer needs of getting a reliable metrology solutions.
            </p>
            <p style={{fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>
              Our team of qualified product sales specialists, applications engineers, and administrative support, have been committed in providing the highest level of customer satisfaction, applications and service support to the manufacturing businesses to achieve outstanding results <b>"The Right Partner for the Best Solution"</b> We believe that our rich experience provides evidence of a sincere, long-term commitment as a right partner to both our customers and our suppliers. Being the partner of the world's leaders of precision metrology company, we continuously providing the best solution for multi-dimensional metrology in the metrology lab and production floor.
            </p>
            </Fade>
          </div>

          <Fade delay={150}>
            <div style={{display:'flex', justifyContent:'space-around', flexDirection:`${window.innerWidth <= 480 ? 'column' : 'row'}`}}>
              {this.state.descriptionCompanyVision && <div style={{width:`${window.innerWidth <= 480 ? '100%' : '30%'}`, padding:'20px', boxSizing:'border-box', textAlign:'left'}}>
                {this.state.imageCompanyVision && <img style={{width: '100%'}} src={this.state.imageCompanyVision} alt='our vision' />}
                <SectionTitle title='Our Vision' />
                {/* <h3 style={{textAlign: 'center'}}>Our Vision</h3> */}
                <div style={{marginTop:'10px', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>
                {this.state.descriptionCompanyVision && this.state.descriptionCompanyVision.length && <div>
                {
                  this.state.descriptionCompanyVision.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-about' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
                </div>
              </div>}
              {this.state.descriptionCompanyMission && <div style={{width:`${window.innerWidth <= 480 ? '100%' : '30%'}`, padding:'20px', boxSizing:'border-box', textAlign:'left'}}>
                {this.state.imageCompanyMission && <img style={{width: '100%'}} src={this.state.imageCompanyMission} alt='our vision' />}
                <SectionTitle title='Our Mission' />
                {/* <h3 style={{textAlign: 'center'}}>Our Vision</h3> */}
                <div style={{marginTop:'10px', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>
                {this.state.descriptionCompanyMission && this.state.descriptionCompanyMission.length && <div>
                {
                  this.state.descriptionCompanyMission.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-about' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
                </div>
              </div>}
              {this.state.descriptionCompanyServices && <div style={{width:`${window.innerWidth <= 480 ? '100%' : '30%'}`, padding:'20px', boxSizing:'border-box', textAlign:'left'}}>
                {this.state.imageCompanyServices && <img style={{width: '100%'}} src={this.state.imageCompanyServices} alt='our vision' />}
                <SectionTitle title='Our Services' />
                {/* <h3 style={{textAlign: 'center'}}>Our Vision</h3> */}
                <div style={{marginTop:'10px', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>
                {this.state.descriptionCompanyServices && this.state.descriptionCompanyServices.length && <div>
                {
                  this.state.descriptionCompanyServices.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-about' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
                </div>
              </div>}
            </div>
          </Fade>
          <Fade delay={150}>
            <div style={{display:'flex', justifyContent:'space-around', flexDirection:`${window.innerWidth <= 480 ? 'column' : 'row'}`}}>
              {this.state.descriptionCompanyValue && <div style={{width:`${window.innerWidth <= 480 ? '100%' : '30%'}`, padding:'20px', boxSizing:'border-box', textAlign:'left'}}>
                {this.state.imageCompanyValues && <img style={{width: '100%'}} src={this.state.imageCompanyValues} alt='our vision' />}
                <SectionTitle title='Our Values' />
                {/* <h3 style={{textAlign: 'center'}}>Our Vision</h3> */}
                <div style={{marginTop:'10px', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>
                {this.state.descriptionCompanyValue && this.state.descriptionCompanyValue.length && <div>
                {
                  this.state.descriptionCompanyValue.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-about' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
                </div>
              </div>}
              {this.state.descriptionFacilityShowroom && <div style={{width:`${window.innerWidth <= 480 ? '100%' : '30%'}`, padding:'20px', boxSizing:'border-box', textAlign:'left'}}>
                {this.state.imageFacilityShowroom && <img style={{width: '100%'}} src={this.state.imageFacilityShowroom} alt='our vision' />}
                <SectionTitle title='Facility - Showroom' />
                {/* <h3 style={{textAlign: 'center'}}>Our Vision</h3> */}
                <div style={{marginTop:'10px', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>
                {this.state.descriptionFacilityShowroom && this.state.descriptionFacilityShowroom.length && <div>
                {
                  this.state.descriptionFacilityShowroom.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-about' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
                </div>
              </div>}
              {/* {this.state.descriptionCompanyCalibrationLab && <div style={{width:`${window.innerWidth <= 480 ? '100%' : '30%'}`, padding:'20px', boxSizing:'border-box', textAlign:'left'}}>
                {this.state.imageCompanyCalibrationLab && <img style={{width: '100%'}} src={this.state.imageCompanyCalibrationLab} alt='our vision' />}
                <SectionTitle title='Facility - Calibration Lab' />
                 <h3 style={{textAlign: 'center'}}>Our Vision</h3> 
                <div style={{marginTop:'10px', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>
                {this.state.descriptionCompanyCalibrationLab && this.state.descriptionCompanyCalibrationLab.length && <div>
                {
                  this.state.descriptionCompanyCalibrationLab.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-about' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
                </div>
              </div>} */}
            </div>
          </Fade>
        </div>
      </MainContainer>
    );
  }
}

export default AboutUs;