import React, {Component} from 'react';
import Header from './components/Header'
import Footer from './components/Footer'
import './App.css';
import { Switch, Route } from 'react-router-dom'
import Home from './containers/Home'
import Brands from './containers/Brands'
import BrandLandingPage from './containers/BrandLandingPage'
import ServicesLandingPage from './containers/ServicesLandingPage'
import CategoriesLandingPage from './containers/CategoriesLandingPage'
import NewsAndEvents from './containers/NewsAndEvent'
import NewsDetail from './containers/NewsDetail'
import Services from './containers/Services'
import AboutUs from './containers/AboutUs'
import ContactUs from './containers/ContactUs'
import NotFound from './containers/404'
import CollectionLandingPage from './containers/CollectionLandingPage'
import CollectionDetailLandingPage from './containers/CollectionDetailLandingPage'
import withTracker from './components/withTracker'
import {AppProvider} from './API/context'
import {getHomePageData, getAllBrandsData, getCompanyListingService} from './API/contentful'

class App extends Component { 
  state =  {
    homePage: null,
    companyListingService: null, 
    brandsData: [],
    windowWidth: window.innerWidth
  }
  async componentWillMount() {
    let brandsData = await getAllBrandsData();
    const homePage = await getHomePageData();
    const companyListingService = await getCompanyListingService();
    this.setState({
      brandsData,
      homePage,
      companyListingService,
    })
  }
  
  render() {
    return ( 
      <AppProvider value={this.state}>
        <div className="App">
        <Header windowWidth={window.innerWidth}/>
        <div style={{backgroundColor:'white', marginTop:'60px', minHeight: 'calc(100vh - 60px - 150px)'}}>
        <Switch>
          <Route path="/" exact component={withTracker(() =><Home context={this.state} />)} />
          <Route path="/products" exact component={withTracker(Brands)} />
          <Route path="/products/:query" exact component={withTracker(() => <BrandLandingPage context={this.state}/>)} />
          <Route path="/collection/:brand/:mainCategory" exact component={withTracker(() => <CollectionLandingPage context={this.state}/>)} />
          <Route path="/collection/:brand/:mainCategory/:detailCategory" exact component={withTracker(() => <CollectionDetailLandingPage context={this.state}/>)} />
          <Route path="/products/:query/:data" exact component={withTracker(() => <CategoriesLandingPage context={this.state}/>)} />
          <Route path="/about" exact component={withTracker(AboutUs)} />
          <Route path="/services" exact component={withTracker(() => <Services context={this.state} />)} />
          <Route path="/services/:query" exact component={withTracker(() => <ServicesLandingPage context={this.state}/>)} />
          <Route path="/news-and-events" exact component={withTracker(NewsAndEvents)} />
          <Route path="/news-and-events/:query" exact component={withTracker(() => <NewsDetail context={this.state}/>)} />
          <Route path="/contact" exact component={withTracker(ContactUs)} />
          <Route component={NotFound} />
        </Switch>
        </div>
        <Footer />
        </div>
      </AppProvider>
    );
  }
}

export default App;
