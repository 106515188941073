import React, {Component, Fragment} from 'react'
import {MainContainer} from '../theme/style'
import {Link, withRouter} from 'react-router-dom'
import ReactMarkdown from 'react-markdown';
import {scrollToTop} from '../API/utils'
import {getNewsDetail} from '../API/contentful'
import {Fade} from 'react-reveal';
import Spinner from './Spinner'
import Metadata from '../components/Metadata'

const BreadCrumbNewsDetail = ({news}) => <div style={{textAlign:'left', fontSize: window.innerWidth > 480 ?'auto' : '14px'}}>
  <Link onClick={scrollToTop} to={'/'} style={{cursor: 'pointer', color:'grey'}}>Home</Link> 
    <span> / </span>
    <Link onClick={scrollToTop} to={'/news-and-events'} style={{color:'grey', cursor:'pointer', textTransform:'capitalize'}}>News and Events</Link>
    <span> / </span>
    <Link onClick={scrollToTop} to={'/news-and-events/' + news} style={{color:'grey', cursor:'pointer', textTransform:'capitalize', textDecoration: 'none', fontWeight:'bold'}}>{news}</Link>
</div>

class NewsDetail extends Component {
    state={
        newsTitle: 'asdf',
        loading: true,
        error: false,
        activeNews: null
    }
    
    async componentDidMount() {
        const categoryName = this.props.location.pathname.split('/')[2];
        if (categoryName) {
            const newsDetail = await getNewsDetail(categoryName) 
            if (newsDetail){
              this.setState({loading: false, activeNews: newsDetail.items[0]})
            } else {
              this.setState({loading: false, error: true})
            }
            this.setState({newsTitle: categoryName})
        } else {
            // this.props.history.replace('/');
        }
    }
    
    _renderBannerBreadCrumb = () => {
        const {activeNews} = this.state;
        if (activeNews && activeNews.title) {
          return <Fragment>
            <Metadata title={activeNews.title} />
            <BreadCrumbNewsDetail news={activeNews.title} />
          </Fragment> 
        }
      }

    render() {
        const renderBannerBreadCrumb = this._renderBannerBreadCrumb();
        return(
          this.state.loading ? <Spinner /> :
            <MainContainer style={{padding:'30px 10px', color:'grey', overflow: 'inherit'}}>
            {renderBannerBreadCrumb}
            {this.state.activeNews && <div class='about-us-image' style={{backgroundImage: `url(${this.state.activeNews.banner})`}}></div>}
        <div className="about-us-container">
          <div className="about-us-info"> 
            <Fade delay={100}>
            {/* {renderAboutUsHeading} */}
            <div>
              {this.state.activeNews && <div style={{margin:'0 0 30px 0'}}>
              <h1 style={{margin: 0, fontWeight:'normal', textAlign:'left'}}>
                {this.state.activeNews.title}
                {/* <ReactMarkdown className='readable-text' escapeHtml={false} skipHtml={false} source={this.state.brand.slogan ? this.state.brand.slogan.content[0].content[0].value : 'slogan placeholder'} /> */}
              </h1>
              <span>Created at: {new Date(this.state.activeNews.publishDate).toString().slice(0, -43)}</span>
            </div>}
            {this.state.activeNews && <div>
                {
                  this.state.activeNews.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-product' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
              {this.state.activeNews && this.state.activeNews.source && this.state.activeNews.source.length ? <div style={{marginTop:'10px'}}>
            {
              this.state.activeNews.source.map((source, index) => <div key={index} style={{textAlign:'left'}}>
                <a href={source} target='_blank' style={{textDecoration:'none', color:'#24326D'}}>
                <i class="fas fa-chevron-right"></i> 
                <span style={{marginLeft:'10px', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>Learn More</span>  
                </a>
              </div>)
            }
            </div> : null}
            </div>
            </Fade>
          </div>

        </div>
            {/* <div style={{margin:'0 0 30px 0'}}>
                <h1 style={{margin: 0, fontWeight:'normal'}}>
                    PT SMART MITRA SOLUTIONS
                    <ReactMarkdown className='readable-text' escapeHtml={false} skipHtml={false} source={this.state.brand.slogan ? this.state.brand.slogan.content[0].content[0].value : 'slogan placeholder'} />
                </h1>
                <SubHeading>The Right Partner for the Best Solutions</SubHeading>
                </div> */}
            </MainContainer>
        );
    }
}

export default withRouter(NewsDetail);