import React from 'react';
import {Fade} from 'react-reveal';

export const SubHeading = ({children, relaxed, services}) => <Fade delay='100'>
  <h6 style={{width: `${relaxed ? window.innerWidth > 480 ? '70%' : '85%' : '100%'}`, margin:'0', color:'grey', fontWeight:'normal', marginBottom:'10px', marginTop:`${services ? '10px' : '0'}`, fontSize:`${window.innerWidth > 480 ? services ? '18px' : '16px' : '14px'}`}}>{children}</h6>
</Fade>

export const MainHeading = ({children, smaller, wider}) => <Fade delay='250'>
  <h1 style={{maxWidth:`${wider ? 'calc(100% - 100px)' : '700px'}`, margin:'0', color:'grey', fontWeight:'normal', fontSize:`${window.innerWidth > 480 ? smaller ?'32px' : '40px' : '16px'}`}}>{children}</h1>
</Fade>

export const HeadingContainer = ({children}) => <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>{children}</div>