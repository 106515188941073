import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom'
import {MainHeading, SubHeading, HeadingContainer} from '../components/HeadingTitle'
import {Fade} from 'react-reveal';
import {MainContainer} from '../theme/style'
import Breadcrumb from '../components/Breadcrumb'
import {scrollToTop, truncate_text, getParameterByName} from '../API/utils'
import {getNewsData} from '../API/contentful'
import ReactMarkdown from 'react-markdown';
import ReactPaginate from 'react-paginate';
import Metadata from '../components/Metadata'



const Pagination = (props) => {
  console.log(props);
  return (
    <div>
        {
          !props.error && (props.news && props.news.length > 0) ?
          <Fragment>
          <ReactPaginate
            previousLabel={(props.currentPage > 1) ?
            <Link onClick={(e) => props.handlePageClick(e)} to={`?page=${props.currentPage-1}`}>Previous</Link> : null}
            nextLabel={(props.limit * props.currentPage >= props.totalNewsCount) ? null :
              <Link onClick={(e) => props.handlePageClick(e)} to={`?page=${+props.currentPage +1}`}>Next</Link>}
            breakClassName={"break-me"}
            pageCount={Math.ceil(props.totalNewsCount / props.limit)}
            marginPagesDisplayed={0}
            pageRangeDisplayed={0}
            forcePage={+props.currentPage}
            onPageChange={(e) => props.handlePageClick(e)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"} />
          </Fragment>
            : null
        }      
    </div>
  )
}

  
class NewsAndEvents extends Component {
  state = {
    news: [],
    activePage: 1,
    limit: 3
  }
  
  async componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      const query = new URLSearchParams(nextProps.location.search);
      let activePage = 1;  
  
      if (query.has('page')) {
        activePage = getParameterByName('page')    
      }
  
      const news = await getNewsData(activePage);
      if (news) {
        this.setState({
          activePage: activePage,
          news: news ? news : []
        })  
      } else {
        this.setState({
          activePage: activePage
        })
      }
    }
  }

  async componentDidMount () {
    const query = new URLSearchParams(this.props.location.search);
    let activePage = 1;  

    if (query.has('page')) {
      activePage = getParameterByName('page')    
    }

    const news = await getNewsData(activePage);
    if (news) {
      this.setState({
        activePage: activePage,
        news: news ? news : []
      })  
    } else {
      this.setState({
        activePage: activePage
      })
    }
  }

  handlePageClick = async (e) => {
    if (e.selected === 0) this.props.history.push('/news-and-events?page=' + (e.selected+1))
    // const news = await getNewsData(page);
    // if (news) {
    //   this.setState({
    //     activePage: page,
    //     news: news
    //   })  
    // } else {
    //   console.error('error fetching news, please try again')
    // }
  }
  
  _renderNews = () => {
    const {news} = this.state;
    let renderNews = null;

    if (news && news.items && news.items.length > 0) {
      renderNews = <Fade>
        <div>
        {
          news.items.map((_news, index) => {
            let content = window.innerWidth > 894 ? truncate_text(_news.content, 1000) : window.innerWidth < 480 ? truncate_text(_news.content, 100) : truncate_text(_news.content, 250);
            let title = window.innerWidth < 480 ? truncate_text(_news.title, 50) : _news.title;
            {/* console.log('[content]', content); */}
            return <div onClick={() => this.props.history.push('/news-and-events/' + _news.slug)} style={{cursor:'pointer', display:'flex', marginBottom:'25px', marginTop:`${index === 0 && window.innerWidth > 480 ? '50px' : '0'}`}}>
            <div style={{width:`${window.innerWidth > 600 ? window.innerWidth > 990 ? '25' : '50%' : '70%'}`, overflow: 'hidden'}}>
              <img style={{width:'100%', height:'100%'}} className='hover-zoom' src={_news.thumbnail} alt='after-sales'/>
            </div>
            <div style={{fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`, backgroundColor:'#eee', textAlign:'left', color:'grey', width:'75%', display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'flex-start', padding:`${window.innerWidth > 480 ? '30px' : '0 20px 20px 20px'}`, boxSizing:'border-box'}}>
              <div>
                  <h3 style={{marginBottom: '0'}}>{title}</h3>
                  <span style={{display:'inline-block', marginBottom:'20px'}}>{new Date(_news.publishDate).toString().slice(0, -43)}</span>
                  <ReactMarkdown className='readable-text' escapeHtml={false} skipHtml={false} source={content} />
              </div>
                <Link onClick={scrollToTop} to={'/news-and-events/' + _news.slug} style={{color:'grey', textDecoration:'underline', cursor:'pointer', textTransform:'capitalize'}}>Learn More</Link>
            </div>
          </div>})
        }
        </div>
    </Fade>
    }
    return renderNews;
  }

    render() {
      let renderNews = this._renderNews();
    
      return(
          <MainContainer className='brands-listing-container' style={{padding:'30px 10px'}}>
            <Metadata title='News And Events' description={`Welcome to our news & events section. This is the place for the latest news on our news, people and customers, as well as information from around the world related to industrial and manufacturing business. Stay current on our latest and upcoming events such as seminar & exhibition. Be sure to check back often to see what's new at PT Smart Mitra Solutions.`} />
            <Breadcrumb breadcrumbs={['News And Events']} />
            <div style={{padding:'30px 0'}}>
            <HeadingContainer>
              <MainHeading>NEWS AND EVENTS</MainHeading>
              <SubHeading relaxed>Welcome to our news & events section. This is the place for the latest news on our news, people and customers, as well as information from around the world related to industrial and manufacturing business. Stay current on our latest and upcoming events such as seminar & exhibition. Be sure to check back often to see what's new at PT Smart Mitra Solutions.</SubHeading>
            </HeadingContainer>
            </div>
            {renderNews}
            {this.state.news && this.state.news.items && this.state.news.items.length &&
              <Pagination news={this.state.news.items} totalNewsCount={this.state.news.total} limit={this.state.limit} currentPage={this.state.activePage} handlePageClick={this.handlePageClick}/>
            }
          </MainContainer>
        );
    }
}

export default NewsAndEvents
