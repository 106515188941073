import React, {Component, Fragment} from 'react';
import {MainHeading, SubHeading, HeadingContainer} from '../components/HeadingTitle'
import {MainContainer} from '../theme/style'
import {Fade} from 'react-reveal';
import Breadcrumb from '../components/Breadcrumb'
import smsLogo from '../assets/sms-logo.gif'
import {getContactUs} from '../API/contentful'
import ReactMarkdown from 'react-markdown';
import GoogleMapReact from 'google-map-react'
import axios from 'axios'
import Metadata from '../components/Metadata'
// import Recaptcha from 'react-recaptcha'

class Map extends Component {
  state = {
    clicked: false,
    page: null,
    widget: null,
    content: null
  }
  render() {
    return (
      <Fragment>
        <div onClick={() => this.setState(prevState => {return {clicked: !prevState.clicked}})} style={{height: '50px', cursor:'pointer', backgroundColor:'white', borderRadius:'50px', border:'1px solid #24326D', marginLeft:'-25px', marginTop:'-25px', display:'flex', justifyContent:'center', alignItems:'center', width:'50px'}}>{ <img src={smsLogo}  style={{height:'30%', marginLeft:'-3px'}}/> }</div>;
        <div className='google-daruma-tooltip' style={this.state.clicked ? {opacity: 1} : {opacity: 0}}><a href="https://www.google.com/maps/place/PT.+Smart+Mitra+Solutions/@-6.351834,107.171189,15z/data=!4m5!3m4!1s0x0:0x8bc435f3df1a601!8m2!3d-6.3518341!4d107.1711892?hl=en-US" target="_blank" style={{width: "max-content", display:'block'}}>View us on Map!</a> </div>
      </Fragment>
    );
  }
}

class ContactUs extends Component {
  static defaultProps = {
    center: { lat: -6.351834, lng: 107.171189 },
    zoom: 17
  }

  state = {
    name: '',
    email: '',
    inquiry: '',
    loading: false,
    mainHeading: null,
    subHeading: null,
    content: [],
    contactEmail: null,
    hotline: null,
    fax: null
  }

  async componentDidMount() {
    const _contactUs = await getContactUs();
    if (_contactUs) {
      console.log('_contactUs', _contactUs)
      this.setState({
        mainHeading: _contactUs.fields.mainHeading.content[0].content[0].value,
        subHeading: _contactUs.fields.subHeading.content[0].content[0].value,
        content: _contactUs.fields.address.content,
        contactEmail: _contactUs.fields.email,
        hotline: _contactUs.fields.hotline,
        fax: _contactUs.fields.fax
      })
    }
  }
  
  
  handleNameInput = (e, type) => {
    if (type === 'name') {
      this.setState({
        name: e.target.value
      })  
    } else if (type === 'email') {
      this.setState({
        email: e.target.value
      })  
    } else if (type === 'inquiry') {
      this.setState({
        inquiry: e.target.value
      })  
    }
  }

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  submitMessage = async (e) => {
    e.preventDefault();
    if (this.validateEmail(this.state.email) === false) {
      alert('Please enter the valid email');
      return;
    }
    this.setState({
      loading: true
    }, async () => {
      let headers = {
          headers: {
            'Content-Type': 'application/json',
            'x-apikey': '73cf060073ad9c09b10445d5fcff820946e92',
            'Cache-Control': 'no-cache'
          }
       }
      
      const data = {
        "to":"info@sms-sg.com",
        "subject":"Inquiry SMS", 
        "html": this.state.inquiry, 
        "company": this.state.email, 
        "sendername": this.state.name
      }

      const sendMail = axios.post('https://smssg-1bec.restdb.io/mail', data, headers);
      if (sendMail) {
        this.setState({
          name: '',
          email: '',
          inquiry: '',
          loading: false
        });
        alert('Thank You, Your Question is Submitted')
      } else {
        this.setState({
          loading: false
        });
        alert('Failed Please try again!')
      }      
      })
  }

  render() {
    return(
      <MainContainer style={{padding:'30px 10px'}}>
        <Metadata title='Contact Us'/>
        <Breadcrumb breadcrumbs={['contact us']} />
        <div style={{margin:'30px 0'}}>
          <HeadingContainer>
            {this.state.mainHeading && <MainHeading>{this.state.mainHeading}</MainHeading>}
            {this.state.subHeading && <SubHeading>{this.state.subHeading}</SubHeading>}
          </HeadingContainer>
        </div>
        <Fade delay={150}>
        <div style={{display:'flex', flexDirection:`${window.innerWidth <= 480 ? 'column' : 'row'}`}}>
          <div style={{padding:`${window.innerWidth <= 480 ? '0' : '50px'}`, paddingRight:'0', boxSizing:'border-box', width:'100%'}}>
            <h2 style={{margin:'0', fontSize:`${window.innerWidth > 480 ? '22px' : '16px'}`, textAlign:'left', fontWeight:'bold', color:'#777', width: 'fit-content', paddingRight:'10px', whiteSpace:'nowrap'}}>Address</h2>
            <div style={{display:'flex', flexDirection:`${window.innerWidth <= 480 ? 'column' : 'row'}`}}>
              <div style={{width:'100%', padding: '10px', paddingLeft:'0', color:'#777', textAlign:'left'}}>
              {this.state.content && this.state.content.length && <div>
                {
                  this.state.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-product' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
                {/* <p style={{textAlign:'left', color:'#777', fontSize:`${window.innerWidth > 480 ? '18px' : '16px'}`}}>PT, Smart Mitra Solutions</p>
                {window.innerWidth > 480 ? <p style={{textAlign:'left', color:'grey'}}>Delta Commercial Park 2,<br/> Jl. Kenari Raya Blok D No. 7,<br/> Lippo Cikarang, Jayamukti,<br/> Cikarang Pusat, <br/>Bekasi<br/> 17530, Indonesia</p> :
              <p style={{textAlign:'left', color:'grey', fontSize:'14px'}}>Delta Commercial Park 2, Jl. Kenari Raya Blok D No. 7, Lippo Cikarang, Jayamukti, Cikarang Pusat, Bekasi, Jawa Barat 17530, Indonesia</p>}
              <p style={{textAlign:'left', color:'grey', marginBottom:'5px', }}><i class="fa fa-envelope" aria-hidden="true"></i> info@sms-sg.com</p>
              <p style={{textAlign:'left', color:'grey', marginBottom:'5px', marginTop: '0'}}><i class="fa fa-phone" aria-hidden="true"></i> +62 8119177772</p>
              <p style={{textAlign:'left', color:'grey', marginBottom:'5px', marginTop: '0'}}><i class="fas fa-fax"></i> +62 21 29094397 </p> */}
              {this.state.contactEmail && <p style={{textAlign:'left', color:'grey', marginBottom:'5px', }}><i class="fa fa-envelope" aria-hidden="true"></i> {this.state.contactEmail}</p>}
              {this.state.hotline && <p style={{textAlign:'left', color:'grey', marginBottom:'5px', marginTop: '0'}}><i class="fa fa-phone" aria-hidden="true"></i> {this.state.hotline}</p>}
              {this.state.fax && <p style={{textAlign:'left', color:'grey', marginBottom:'5px', marginTop: '0'}}><i class="fas fa-fax"></i> {this.state.fax}</p>}
              </div>
              <div style={{width:'100%'}}>
                <div className='google-map'>
                {/* AIzaSyCjEWYfohg_3nPAgNTOKNDuvIqMNIprolk */}
                {/* AIzaSyD3LR0G5DBNMZICB8jUwJEyy0TrAxjCF-Y */}
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyBRHcpzHBNOR27iPwiw5xL5wFA3a9T9jUw' }}
      defaultCenter={ this.props.center }
      defaultZoom={ this.props.zoom }>
      <Map
        lat={ -6.351834 }
        lng={ 107.171189 }
        // text={ "Where's Waldo?" }
      />
    </GoogleMapReact>
  </div>
              </div>
            </div>
          </div>
          <div style={{padding:`${window.innerWidth <= 480 ? '25px 0' : '50px'}`, boxSizing:'border-box', width:'100%'}}>
            <h2 style={{margin:'0', fontSize:`${window.innerWidth > 480 ? '22px' : '16px'}`, textAlign:'left', fontWeight:'bold', color:'#777', width: 'fit-content', paddingRight:'10px', whiteSpace:'nowrap'}}>Ask a Question</h2>
            <form>
              <input onChange={e => this.handleNameInput(e, 'name')} value={this.state.name} placeholder='Name' type="text"/>
              <input onChange={e => this.handleNameInput(e, 'email')} value={this.state.email}  placeholder='Email' type="email"/>
              <textarea  onChange={e => this.handleNameInput(e, 'inquiry')} value={this.state.inquiry} placeholder='Message' name="inquiry" id="" cols="30" rows="5"></textarea>
              <button className='blue-hover' onClick={this.submitMessage}>Submit</button>
            </form>
          </div>
        </div>

        </Fade>
        {/* <Recaptcha
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        /> */}
      </MainContainer>
    );
  }
}

export default ContactUs;