import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom'
import axios from 'axios'
import ReactMarkdown from 'react-markdown';
import {MainContainer} from '../theme/style'
import {MainHeading, SubHeading, HeadingContainer} from '../components/HeadingTitle'
import SectionTitle from '../components/SectionTitle'
import {getAfterSalesServices} from '../API/contentful'
import Breadcrumb from '../components/Breadcrumb'
import {Fade} from 'react-reveal';
import Metadata from '../components/Metadata'

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
class ServicesLandingPage extends Component {
  state = {
    title: null,
    description: null,
    howDoesItWorkStep1: null,
    howDoesItWorkStep1Description: [],
    howDoesItWorkStep2: null,
    howDoesItWorkStep2Description: [],
    howDoesItWorkStep3: null,
    howDoesItWorkStep3Description: [],
    howLongDoesItTakeToFinish: [],
    moreDetailFinalOutput: null,
    moreDetailFinalOutputDescription: [],
    moreDetailMachines: null,
    moreDetailMachinesDescription: [],
    moreDetailSoftwareDescription: [],
    moreDetailSoftwares: null,
    afterSalesServices: [],
    name: '',
    email: '',
    inquiry: '',
    loading: false,
    selectedFile: null
  }

  onChangeHandler= event =>{
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })
  }

  handleNameInput = (e, type) => {
    if (type === 'name') {
      this.setState({
        name: e.target.value
      })  
    } else if (type === 'email') {
      this.setState({
        email: e.target.value
      })  
    } else if (type === 'inquiry') {
      this.setState({
        inquiry: e.target.value
      })  
    }
  }

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  sendFile = async file => {
    try {
      var formData = new FormData();
      formData.append('filedata', this.state.selectedFile)
      // const transfer = await axios.post('https://fields.file.io', formData);
        const transfer = await axios.post('https://cors-anywhere.herokuapp.com/https://transfer.sh', formData);
      return transfer.data;
    } catch(e) {
      try {
        var formData = new FormData();
        formData.append('filedata', this.state.selectedFile)
        // const transfer = await axios.post('https://fields.file.io', formData);
        const transfer = await axios.post('https://cors.io/?https://transfer.sh', formData);
        return transfer.data;
  
      } catch(e) {
        console.error(e.response);
      }
    }
  }
   
  submitMessage = async (e) => {
    e.preventDefault();

    this.setState({
      loading: true
    }, async () => {
      
    let fileUrl = null;

    if (this.state.selectedFile) {
      fileUrl = await this.sendFile(this.state.selectedFile);
      console.log('fileUrl', fileUrl)
      if (this.validateEmail(this.state.email) === false) {
        alert('Please enter the valid email');
        return;
      } else if (fileUrl) {
        let headers = {
          headers: {
            'Content-Type': 'application/json',
            'x-apikey': process.env.REACT_APP_RESTDB_KEY,
            'Cache-Control': 'no-cache'
          }
       }
        const data = {
          "to":"sales@sms-sg.com",
          "subject":"Inquiry SMS " + this.state.title, 
          "html": `
            ${this.state.inquiry}
            <br/>
            <br/>
            <br/>
            ${fileUrl ? 'Attached File URL: ' + fileUrl : ''}
          `, 
          "company": this.state.email, 
          "sendername": this.state.name
        }
  
        const sendMail = axios.post(process.env.REACT_APP_REST_DB_URL, data, headers);
        if (sendMail) {
          this.setState({
            name: '',
            email: '',
            inquiry: '',
            loading: false
          });
          alert('Thank You, Your Question is Submitted')
        } else {
          this.setState({
            loading: false
          });
          alert('Failed please try again!')
        }      
      } else {
        alert('Upload File Failed, Please Try Again!')
      }
    } else {
      if (this.validateEmail(this.state.email) === false) {
        alert('Please enter the valid email');
        return;
      } else {
        let headers = {
          headers: {
            'Content-Type': 'application/json',
            'x-apikey': process.env.REACT_APP_RESTDB_KEY,
            'Cache-Control': 'no-cache'
          }
       }
        const data = {
          "to":"sales@sms-sg.com",
          "subject":"Inquiry SMS " + this.state.title, 
          "html": `
            ${this.state.inquiry}
          `, 
          "company": this.state.email, 
          "sendername": this.state.name
        }
  
        const sendMail = axios.post(process.env.REACT_APP_REST_DB_URL, data, headers);
        if (sendMail) {
          this.setState({
            name: '',
            email: '',
            inquiry: '',
            loading: false
          });
          alert('Thank You, Your Question is Submitted')
        } else {
          this.setState({
            loading: false
          });
          alert('Failed Please try again!')
        }      
      }
  
    }
    
    })
  }
  componentDidMount() {
    this.init();
  }

  init = async () => {
    if(!this.props.match.params.query) {
      this.props.history.replace('/')
    } else {
    const companyService = this.props.context.companyListingService.items[0].fields;
    const {services} = companyService;
      if (this.props.match.params.query === 'scanning-inspection') {
        const scanning = services.filter(service => service.fields.slug === "scanning-inspection")[0];
        const {fields} = scanning;
        this.setState({
          title: fields.title,
          description: fields.description,
          howDoesItWorkStep1: fields.howDoesItWorkStep1.fields.file.url,
          howDoesItWorkStep1Description: fields.howDoesItWorkStep1Description.content,
          howDoesItWorkStep2: fields.howDoesItWorkStep2.fields.file.url,
          howDoesItWorkStep2Description: fields.howDoesItWorkStep2Description.content,
          howDoesItWorkStep3: fields.howDoesItWorkStep3.fields.file.url,
          howDoesItWorkStep3Description: fields.howDoesItWorkStep3Description.content,
          howLongDoesItTakeToFinish: fields.howLongDoesItTakeToFinish.content,
          moreDetailFinalOutput: fields.moreDetailFinalOutput.fields.file.url,
          moreDetailFinalOutputDescription: fields.moreDetailFinalOutputDescription.content,
          moreDetailMachines: fields.moreDetailMachines.fields.file.url,
          moreDetailMachinesDescription: fields.moreDetailMachinesDescription.content,
          moreDetailSoftwareDescription: fields.moreDetailSoftwareDescription.content,
          moreDetailSoftwares: fields.moreDetailSoftwares.fields.file.url,
        })
      } else if (this.props.match.params.query === 'probing-inspection') {
        const probing = services.filter(service => service.fields.slug === "probing-inspection")[0];
        const {fields} = probing;
        this.setState({
          title: fields.title,
          description: fields.description,
          howDoesItWorkStep1: fields.howDoesItWorkStep1.fields.file.url,
          howDoesItWorkStep1Description: fields.howDoesItWorkStep1Description.content,
          howDoesItWorkStep2: fields.howDoesItWorkStep2.fields.file.url,
          howDoesItWorkStep2Description: fields.howDoesItWorkStep2Description.content,
          howDoesItWorkStep3: fields.howDoesItWorkStep3.fields.file.url,
          howDoesItWorkStep3Description: fields.howDoesItWorkStep3Description.content,
          howLongDoesItTakeToFinish: fields.howLongDoesItTakeToFinish.content,
          moreDetailFinalOutput: fields.moreDetailFinalOutput.fields.file.url,
          moreDetailFinalOutputDescription: fields.moreDetailFinalOutputDescription.content,
          moreDetailMachines: fields.moreDetailMachines.fields.file.url,
          moreDetailMachinesDescription: fields.moreDetailMachinesDescription.content,
          moreDetailSoftwareDescription: fields.moreDetailSoftwareDescription.content,
          moreDetailSoftwares: fields.moreDetailSoftwares.fields.file.url,
        })
      } else if (this.props.match.params.query === 'reverse-engineering') {
        const reverse = services.filter(service => service.fields.slug === "reverse-engineering")[0];
        const {fields} = reverse;
        this.setState({
          title: fields.title,
          description: fields.description,
          howDoesItWorkStep1: fields.howDoesItWorkStep1.fields.file.url,
          howDoesItWorkStep1Description: fields.howDoesItWorkStep1Description.content,
          howDoesItWorkStep2: fields.howDoesItWorkStep2.fields.file.url,
          howDoesItWorkStep2Description: fields.howDoesItWorkStep2Description.content,
          howDoesItWorkStep3: fields.howDoesItWorkStep3.fields.file.url,
          howDoesItWorkStep3Description: fields.howDoesItWorkStep3Description.content,
          howLongDoesItTakeToFinish: fields.howLongDoesItTakeToFinish.content,
          moreDetailFinalOutput: fields.moreDetailFinalOutput.fields.file.url,
          moreDetailFinalOutputDescription: fields.moreDetailFinalOutputDescription.content,
          moreDetailMachines: fields.moreDetailMachines.fields.file.url,
          moreDetailMachinesDescription: fields.moreDetailMachinesDescription.content,
          moreDetailSoftwareDescription: fields.moreDetailSoftwareDescription.content,
          moreDetailSoftwares: fields.moreDetailSoftwares.fields.file.url,
        })
      } else if (this.props.match.params.query === 'machine-rental') {
        const machine = services.filter(service => service.fields.slug === "machine-rental")[0];
        const {fields} = machine;
        this.setState({
          title: fields.title,
          description: fields.description,
          howDoesItWorkStep1: fields.howDoesItWorkStep1.fields.file.url,
          howDoesItWorkStep1Description: fields.howDoesItWorkStep1Description.content,
          howDoesItWorkStep2: fields.howDoesItWorkStep2.fields.file.url,
          howDoesItWorkStep2Description: fields.howDoesItWorkStep2Description.content,
          howDoesItWorkStep3: fields.howDoesItWorkStep3.fields.file.url,
          howDoesItWorkStep3Description: fields.howDoesItWorkStep3Description.content,
          howLongDoesItTakeToFinish: fields.howLongDoesItTakeToFinish.content,
          moreDetailFinalOutput: fields.moreDetailFinalOutput.fields.file.url,
          moreDetailFinalOutputDescription: fields.moreDetailFinalOutputDescription.content,
          moreDetailMachines: fields.moreDetailMachines.fields.file.url,
          moreDetailMachinesDescription: fields.moreDetailMachinesDescription.content,
          moreDetailSoftwareDescription: fields.moreDetailSoftwareDescription.content,
          moreDetailSoftwares: fields.moreDetailSoftwares.fields.file.url,
        })
      } else if (this.props.match.params.query === '3d-printing') {
        const printing = services.filter(service => service.fields.slug === "3d-printing")[0];
        const {fields} = printing;
        this.setState({
          title: fields.title,
          description: fields.description,
          howDoesItWorkStep1: fields.howDoesItWorkStep1.fields.file.url,
          howDoesItWorkStep1Description: fields.howDoesItWorkStep1Description.content,
          howDoesItWorkStep2: fields.howDoesItWorkStep2.fields.file.url,
          howDoesItWorkStep2Description: fields.howDoesItWorkStep2Description.content,
          howDoesItWorkStep3: fields.howDoesItWorkStep3.fields.file.url,
          howDoesItWorkStep3Description: fields.howDoesItWorkStep3Description.content,
          howLongDoesItTakeToFinish: fields.howLongDoesItTakeToFinish.content,
          moreDetailFinalOutput: fields.moreDetailFinalOutput.fields.file.url,
          moreDetailFinalOutputDescription: fields.moreDetailFinalOutputDescription.content,
          moreDetailMachines: fields.moreDetailMachines.fields.file.url,
          moreDetailMachinesDescription: fields.moreDetailMachinesDescription.content,
          moreDetailSoftwareDescription: fields.moreDetailSoftwareDescription.content,
          moreDetailSoftwares: fields.moreDetailSoftwares.fields.file.url,
        })
      } else if (this.props.match.params.query === 'after-services') {
        const afterSalesServices = await getAfterSalesServices();
        if (afterSalesServices) {
          const {services} = afterSalesServices.items[0].fields;
          this.setState({afterSalesServices: services})
          console.log(services);
        }

        this.setState({title: 'After Sales Services'})
      } else {
        this.props.history.replace('/')
      }
    }
  } 
  
  handleGoToProductDetail = (slug) => {
    this.props.history.push(this.props.location.pathname + '/' + slug);
  }

  _renderBannerBreadCrumb = () => {
    const {title} = this.state;
      return <Breadcrumb breadcrumbs={['services', title]} />
  }
  
  _renderBrandSlogan = () => {
    const {title} = this.state;
      return <HeadingContainer>
          <Metadata title={title} />
          <MainHeading>
            {title}
          </MainHeading>
        </HeadingContainer>
  }
  
  _renderBrandSubSlogan = () => {
    const {description, title} = this.state;
      return <HeadingContainer>
          <SubHeading relaxed services>
          {description && description.content && description.content.length && <div>
                {
                  description.content.map(_items => 
                    <Fragment>
                          <Metadata title={title} description={_items.content[0].value} />
                    {
                      _items.content.map(item =>  
                          <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />
                          )
                      }
                      </Fragment>
                    )
                }
              </div>}
          </SubHeading>
        </HeadingContainer>
  }


  render() {
    const {title} = this.state;
    const renderBreadCrumb = this._renderBannerBreadCrumb();
    const renderBrandSlogan = this._renderBrandSlogan();
    const renderBrandSubSlogan = this._renderBrandSubSlogan();
    const renderSection = <Fragment>
        <SectionTitle title={'How Does It Work?'}/>
          <div style={{margin:'30px 0'}}>
            <div style={{display:'flex', justifyContent:'space-around', flexDirection:`${window.innerWidth <= 480 ? 'column' : 'row'}`}}>
              <div style={{width:`${window.innerWidth <= 480 ? '100%' : '33%'}`, padding:'20px', boxSizing:'border-box'}}>
                <img src={this.state.howDoesItWorkStep1} style={{height:`${window.innerWidth > 480 ? '150px' : '100px'}`}} alt=""/>
                <div>
                {this.state.howDoesItWorkStep1Description && this.state.howDoesItWorkStep1Description.length && <div>
                {
                  this.state.howDoesItWorkStep1Description.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-serviceslanding' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
                </div>
              </div>
              <div style={{width:`${window.innerWidth <= 480 ? '100%' : '33%'}`, padding:'20px', boxSizing:'border-box'}}>
                <img src={this.state.howDoesItWorkStep2} style={{height:`${window.innerWidth > 480 ? '150px' : '100px'}`}} alt=""/>
                {this.state.howDoesItWorkStep2Description && this.state.howDoesItWorkStep2Description.length && <div>
                {
                  this.state.howDoesItWorkStep2Description.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-serviceslanding' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}              
              </div>
              <div style={{width:`${window.innerWidth <= 480 ? '100%' : '33%'}`, padding:'20px', boxSizing:'border-box'}}>
                <img src={this.state.howDoesItWorkStep3} style={{height:`${window.innerWidth > 480 ? '150px' : '100px'}`}} alt=""/>
                {this.state.howDoesItWorkStep3Description && this.state.howDoesItWorkStep3Description.length && <div>
                {
                  this.state.howDoesItWorkStep3Description.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-serviceslanding' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}  
              </div>
            </div>
          </div>
        <SectionTitle title={'More Detail About ' +  title}/>
        <div style={{display:'flex', justifyContent:'space-around', margin:'30px 0', flexDirection:`${window.innerWidth <= 480 ? 'column' : 'row'}`}}>
              <div style={{width:`${window.innerWidth <= 480 ? '100%' : '33%'}`, padding:'20px', boxSizing:'border-box'}}>
                <img src={this.state.moreDetailMachines} style={{height:`${window.innerWidth > 480 ? '150px' : '100px'}`}} alt=""/>
                <br/>Machines: 
                {
                  this.state.moreDetailMachinesDescription.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-serviceslanding no-margin-top' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                } 
              </div>
              <div style={{width:`${window.innerWidth <= 480 ? '100%' : '33%'}`, padding:'20px', boxSizing:'border-box'}}>
                <img src={this.state.moreDetailSoftwares} style={{height:`${window.innerWidth > 480 ? '150px' : '100px'}`}} alt=""/>
                <br/>Softwares: 
                {
                  this.state.moreDetailSoftwareDescription.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-serviceslanding no-margin-top' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>
              <div style={{width:`${window.innerWidth <= 480 ? '100%' : '33%'}`, padding:'20px', boxSizing:'border-box'}}>
                <img src={this.state.moreDetailFinalOutput} style={{height:`${window.innerWidth > 480 ? '150px' : '100px'}`}} alt=""/>
                <br/>Final Output: 
                {
                  this.state.moreDetailFinalOutputDescription.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-serviceslanding no-margin-top' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }                
              </div>
            </div>
        <SectionTitle title={'How long does it take to finish a measurement?'}/>
        <div style={{margin:'60px 0'}}>
          <blockquote>
          {this.state.howLongDoesItTakeToFinish && 
            this.state.howLongDoesItTakeToFinish.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-serviceslanding' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
          }
          </blockquote>
        </div>      
      </Fragment>

    return(
      <MainContainer style={{padding:'30px 10px'}}>
        {renderBreadCrumb}
        <div style={{padding:'30px 0'}}>
          {renderBrandSlogan}
          {renderBrandSubSlogan}
        </div>
        <Fade delay={150}>
        {(this.props.match.params.query !== 'machine-rental') && (this.props.match.params.query !== 'after-services')  ? renderSection : ''}
        {
          this.props.match.params.query === 'after-services' ?
          <div style={{color:'grey'}}>
            {
              this.state.afterSalesServices && this.state.afterSalesServices.length && <Fragment>
                {
                  this.state.afterSalesServices.map((service, index) => <div style={{display:'flex', flexDirection:`${window.innerWidth > 480 ? index % 2 !== 0 ? 'row-reverse' : 'row' : 'column'}`}}>
              <div style={{width:`${window.innerWidth > 480 ? '50%' : '100%'}`, padding:'10px', boxSizing:'border-box'}}>
                <img style={{width:'100%'}} src={service.fields.mainImage.fields.file.url} alt='after-sales'/>
              </div>
              <div style={{width:`${window.innerWidth > 480 ? '50%' : '100%'}`, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding:'10px', boxSizing:'border-box'}}>
                <h3 style={{color:'#777', fontSize:`${window.innerWidth > 480 ? '16px' : '16px'}`}}>{service.fields.title}</h3>
                {
                  service.fields.serviceDetail.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-serviceslanding no-margin-top' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }   
              </div>
            </div>)
                }
              </Fragment>
            }
          </div>
          :
          <Fragment>
            <SectionTitle title={'How much does it cost?'}/>
            <div style={{margin:'60px 0', marginTop:`${window.innerWidth > 480 ? '60px' : '30px'}`}}>
              <p style={{fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>Get an estimated price of the service by filling out the form below:</p>
                <div style={{maxWidth:'600px', margin:'auto'}}>
                <input type="hidden" name="wt_embed_output" class="wt_embed_output" />
                <form>
                  <input onChange={e => this.handleNameInput(e, 'name')} value={this.state.name} placeholder='Name' type="text"/>
                  <input onChange={e => this.handleNameInput(e, 'email')} value={this.state.email}  placeholder='Email' type="email"/>
                  <textarea  onChange={e => this.handleNameInput(e, 'inquiry')} value={this.state.inquiry} placeholder='Message' name="inquiry" id="" cols="30" rows="5"></textarea>
                  <label style={{fontSize: '14px', textAlign:'left'}}>Attach File (optional):</label>
                  <input type="file" name="file" onChange={this.onChangeHandler}/>
                  <button className='blue-hover' onClick={this.submitMessage}>{this.state.loading ? 'Please Wait...' : 'Submit'}</button>
                </form>                   
                </div>
            </div>
          </Fragment>         
        }
        </Fade>
      </MainContainer>
    );
  }
}

export default withRouter(ServicesLandingPage);
