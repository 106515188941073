import React, {Fragment} from 'react';
import {MainHeading, SubHeading, HeadingContainer} from './HeadingTitle'
import Slider from 'react-slick'
import {Fade} from 'react-reveal';
import {AppConsumer} from '../API/context'
import ReactMarkdown from 'react-markdown';
import Metadata from './Metadata'

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1
};


const Banner = () => <AppConsumer>
  {
    ({homePage, windowWidth}) => homePage && <Fragment>
        <HeadingContainer>
          <SubHeading>{homePage.subHeading}</SubHeading>
          <MainHeading><ReactMarkdown className='readable-text center main-title-text' escapeHtml={false} skipHtml={false} source={homePage.mainHeading} /></MainHeading>
        </HeadingContainer>
        <Fade delay={250}><div style={{marginTop:'30px', marginBottom:'20px'}}>
          <Slider {...settings}>
            {
              windowWidth > 480 ? 
              homePage.bannerDesktop.map(img => <div><img style={{width:'100%'}} src={img}/></div>)
              :
              homePage.bannerMobile.map(img => <div><img style={{width:'100%'}} src={img}/></div>)
            }
          </Slider>
          <h6 style={{color:'grey', fontWeight:'normal', padding:'20px 0', fontSize:'16px', maxWidth:'500px', margin:'auto', fontSize:`${window.innerWidth > 480 ? '16px' : '14px'}`}}>We help companies to excel in product quality assurance, reverse engineering, and prototyping.</h6>
        </div>
        </Fade>
        <Metadata description={homePage.mainHeading} />
  </Fragment> 
  }
</AppConsumer>

export default Banner;