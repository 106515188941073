import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router';
import {MainContainer} from '../theme/style'
import HomeBanner from '../components/HomeBanner'
import Footer from '../components/Footer'
import Services from '../components/Services'
import HomeBrands from '../components/HomeBrands'
import Breadcrumb from '../components/Breadcrumb'

class Home extends Component {
  render() {
    return (<MainContainer style={{paddingTop:'30px'}}>
    <HomeBanner />
    <HomeBrands />
    <Services services={this.props.context && this.props.context.companyListingService && this.props.context.companyListingService.items && this.props.context.companyListingService.items[0] && this.props.context.companyListingService.items[0].fields.services} />
  </MainContainer>)
  }
}

export default withRouter(Home);