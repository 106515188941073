import React, {Component} from 'react';
import {MainContainer} from '../theme/style'
import Breadcrumb from '../components/Breadcrumb'
import ReactMarkdown from 'react-markdown';
import {Fade} from 'react-reveal';
import {Link} from 'react-router-dom';
import {scrollToTop} from '../API/utils'
import {MainHeading, SubHeading, HeadingContainer} from '../components/HeadingTitle'
import {getHomePageData, getAllBrandsData, getCompanyListingService} from '../API/contentful'
import Metadata from '../components/Metadata'

class Services extends Component {
  state =  {
    companyListingService: JSON.parse(localStorage.getItem('companyListingService')) || null,
  }
  async componentDidMount() {
    let brandsData = await getAllBrandsData();
    const homePage = await getHomePageData();
    const companyListingService = await getCompanyListingService();
    // brandsData = deepClone(brandsData)
    this.setState({companyListingService})
    // console.log('[CompanyListingService]', companyListingService)
  }

  render() {
    let companyService = null;
    if (this.state.companyListingService && this.state.companyListingService.items[0] && this.state.companyListingService.items[0].fields) {
      companyService = this.state.companyListingService.items[0].fields;
    };
    let services = null;
    if (companyService) {
      services = companyService.services;
    }
    let probing = null
    let scanning = null
    let reverse = null
    let machine = null
    let printing = null
    let afterSales = null
    if (services && services.length) {
      probing = services.filter(service => service.fields.slug === "probing-inspection")[0];
      scanning = services.filter(service => service.fields.slug === "scanning-inspection")[0];
      reverse = services.filter(service => service.fields.slug === "reverse-engineering")[0];
      machine = services.filter(service => service.fields.slug === "machine-rental")[0];
      printing = services.filter(service => service.fields.slug === "3d-printing")[0];
      afterSales = services.filter(service => service.fields.slug === "after-sales-services")[0];  
    }
    return(
      <MainContainer style={{padding:'30px 10px'}}>
        <Metadata title='Services' />
        <Breadcrumb breadcrumbs={['services']} description={companyService && companyService.description ? companyService.description : false}/>
        <div style={{margin:'30px 0'}}>
        <HeadingContainer>
          {companyService && companyService.title && <MainHeading>{companyService.title}</MainHeading>}
          {companyService && companyService.description && <SubHeading relaxed>{companyService.description}</SubHeading>}
        </HeadingContainer>
        </div>
        <Fade><div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start', textAlign:'left', color:'grey', fontSize:'16px', flexDirection:`${window.innerWidth <= 480 ? 'column' : 'row'}`}}>
        {probing && <div style={{padding:'20px 10px', display:'flex', justifyContent:'center', boxSizing:'border-box', width:`${window.innerWidth <= 480 ? '100%' : '25%'}`, flexDirection:'column'}}>
          <div style={{color:'#777', fontSize:'30px', padding:'20px', textAlign:'center'}}>
            {companyService && <img style={{width:'50px', height:'50px'}} src={probing.fields.logo.fields.file.url} alt="probing"/>}
          </div>
          <div style={{textAlign:'center', minHeight:`${window.innerWidth <= 480 ? 'auto' : '185px'}`}}>
            {companyService && <h2 style={{color:'#777', margin:'0', fontSize:'16px'}}>{probing.fields.title}</h2>}
            {probing && probing.fields && probing.fields.description && probing.fields.description.content && probing.fields.description.content.length && <div>
                {
                  probing.fields.description.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
          </div>
          <div style={{textAlign:'center'}}>
            <Link onClick={scrollToTop} className={'blue-hover'} style={{borderRadius:'3px', padding:'10px', color:'white', backgroundColor:'grey', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}} to='/services/probing-inspection'>Learn More</Link>
          </div>
        </div>}
        {scanning && <div style={{padding:'20px 10px', display:'flex', justifyContent:'center', boxSizing:'border-box', width:`${window.innerWidth <= 480 ? '100%' : '25%'}`, flexDirection:'column'}}>
          <div style={{color:'#777', fontSize:'30px', padding:'20px', textAlign:'center'}}>
            {companyService && <img style={{width:'50px', height:'50px'}} src={scanning.fields.logo.fields.file.url} alt="scanning"/>}
          </div>
          <div style={{textAlign:'center', minHeight:`${window.innerWidth <= 480 ? 'auto' : '185px'}`}}>
            {companyService && <h2 style={{color:'#777', margin:'0', fontSize:'16px'}}>{scanning.fields.title}</h2>}
            {scanning && scanning.fields && scanning.fields.description && scanning.fields.description.content && scanning.fields.description.content.length && <div>
                {
                  scanning.fields.description.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
          </div>
          <div style={{textAlign:'center'}}>
            <Link onClick={scrollToTop} className={'blue-hover'} style={{borderRadius:'3px', padding:'10px', color:'white', backgroundColor:'grey', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}} to='/services/scanning-inspection'>Learn More</Link>
          </div>
        </div>}
        {<div style={{padding:'20px 10px', display:'flex', justifyContent:'center', boxSizing:'border-box', width:`${window.innerWidth <= 480 ? '100%' : '25%'}`, flexDirection:'column'}}>
          <div style={{color:'#777', fontSize:'30px', padding:'20px', textAlign:'center'}}>
            {companyService && <img style={{width:'50px', height:'50px'}} src={reverse.fields.logo.fields.file.url} alt="reverse engineering"/>}
          </div>
          <div style={{textAlign:'center', minHeight:`${window.innerWidth <= 480 ? 'auto' : '185px'}`}}>
            {companyService && <h2 style={{color:'#777', margin:'0', fontSize:'16px'}}>{reverse.fields.title}</h2>}
            {reverse && reverse.fields && reverse.fields.description && reverse.fields.description.content && reverse.fields.description.content.length && <div>
                {
                  reverse.fields.description.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}

          </div>
          <div style={{textAlign:'center'}}>
            <Link onClick={scrollToTop} className={'blue-hover'} style={{borderRadius:'3px', padding:'10px', color:'white', backgroundColor:'grey', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}} to='/services/reverse-engineering'>Learn More</Link>
          </div>
        </div>}
        </div>
        </Fade>
        <Fade>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start', textAlign:'left', color:'grey', fontSize:'16px', flexDirection:`${window.innerWidth <= 480 ? 'column' : 'row'}`}}>
        {machine && <div style={{padding:'20px 10px', display:'flex', justifyContent:'center', boxSizing:'border-box', width:`${window.innerWidth <= 480 ? '100%' : '25%'}`, flexDirection:'column'}}>
          <div style={{color:'#777', fontSize:'30px', padding:'20px', textAlign:'center'}}>
          {companyService && <img style={{width:'50px', height:'50px'}} src={machine.fields.logo.fields.file.url} alt="machine-rental"/>}
          </div>
          <div style={{textAlign:'center', minHeight:`${window.innerWidth <= 480 ? 'auto' : '185px'}`}}>
            {companyService && <h2 style={{color:'#777', margin:'0', fontSize:'16px'}}>{machine.fields.title}</h2>}
            {machine && machine.fields && machine.fields.description && machine.fields.description.content && machine.fields.description.content.length && <div>
                {
                  machine.fields.description.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
          </div>
          <div style={{textAlign:'center'}}>
            <Link onClick={scrollToTop} className={'blue-hover'} style={{borderRadius:'3px', padding:'10px', color:'white', backgroundColor:'grey', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}} to='/services/machine-rental'>Learn More</Link>
          </div>
        </div>}
        {printing && <div style={{padding:'20px 10px', display:'flex', justifyContent:'center', boxSizing:'border-box', width:`${window.innerWidth <= 480 ? '100%' : '25%'}`, flexDirection:'column'}}>
          <div style={{color:'#777', fontSize:'30px', padding:'20px', textAlign:'center'}}>
          {companyService && <img style={{width:'50px', height:'50px'}} src={printing.fields.logo.fields.file.url} alt="3d-printing"/>}
          </div>
          <div style={{textAlign:'center', minHeight:`${window.innerWidth <= 480 ? 'auto' : '185px'}`}}>
            {companyService && <h2 style={{color:'#777', margin:'0', fontSize:'16px'}}>{printing.fields.title}</h2>}
            {printing && printing.fields && printing.fields.description && printing.fields.description.content && printing.fields.description.content.length && <div>
                {
                  printing.fields.description.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
          </div>
          <div style={{textAlign:'center'}}>
            <Link onClick={scrollToTop} className={'blue-hover'} style={{borderRadius:'3px', padding:'10px', color:'white', backgroundColor:'grey', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}} to='/services/3d-printing'>Learn More</Link>
          </div>
        </div>}
        {afterSales && <div style={{padding:'20px 10px', display:'flex', justifyContent:'center', boxSizing:'border-box', width:`${window.innerWidth <= 480 ? '100%' : '25%'}`, flexDirection:'column'}}>
          <div style={{color:'#777', fontSize:'30px', padding:'20px', textAlign:'center'}}>
          {companyService && <img style={{width:'50px', height:'50px'}} src={afterSales.fields.logo.fields.file.url} alt="after-sales"/>}
          </div>
          <div style={{textAlign:'center', minHeight:`${window.innerWidth <= 480 ? 'auto' : '185px'}`}}>
            {companyService && <h2 style={{color:'#777', margin:'0', fontSize:'16px'}}>{afterSales.fields.title}</h2>}
            {afterSales && afterSales.fields && afterSales.fields.description && afterSales.fields.description.content && machine.fields.description.content.length && <div>
                {
                  afterSales.fields.description.content.map(_items => 
                      {
                        return _items.content.map(item =>  <ReactMarkdown className='readable-text-services' escapeHtml={false} skipHtml={false} source={item.value} />)
                      }
                    )
                }
              </div>}
          </div>
          <div style={{textAlign:'center'}}>
            <Link onClick={scrollToTop} className={'blue-hover'} style={{borderRadius:'3px', padding:'10px', color:'white', backgroundColor:'grey', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}} to='/services/after-services'>Learn More</Link>
          </div>
        </div>}
      </div>
      </Fade>
      </MainContainer>
    );
  }
}

export default Services;