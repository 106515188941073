export const scrollToTop = () => window.scrollTo(0,0);

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const truncate_text = function(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export function deepClone(obj, hash = new WeakMap()) {
  // Do not try to clone primitives or functions
  if (Object(obj) !== obj || obj instanceof Function) return obj;
  if (hash.has(obj)) return hash.get(obj); // Cyclic reference
  try { // Try to run constructor (without arguments, as we don't know them)
      var result = new obj.constructor();
  } catch(e) { // Constructor failed, create object without running the constructor
      result = Object.create(Object.getPrototypeOf(obj));
  }
  // Optional: support for some standard constructors (extend as desired)
  if (obj instanceof Map)
      Array.from(obj, ([key, val]) => result.set(deepClone(key, hash), 
                                                 deepClone(val, hash)) );
  else if (obj instanceof Set)
      Array.from(obj, (key) => result.add(deepClone(key, hash)) );
  // Register in hash    
  hash.set(obj, result);
  // Clone and assign enumerable own properties recursively
  return Object.assign(result, ...Object.keys(obj).map (
      key => ({ [key]: deepClone(obj[key], hash) }) ));
}