import styled from 'styled-components';

const XXS = 320;
const XS = 480;
const S = 640;
const M = 768;
const L = 992;
const XL = 1200;

export const MainContainer = styled.div `
  /* min-height: 60px; */
  width: 100vw;
  overflow-x: hidden;
  box-sizing: border-box;
  @media(max-width: ${XXS}px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  @media(max-width: ${XS}px) {
    padding-left: 8px;
    padding-right: 8px;
  }
  @media(max-width: ${M}px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media(min-width: ${M}px) and (max-width:${L}px) {
    max-width: 728px;
    margin: auto;
  }
  @media(min-width: ${L}px) and (max-width:${XL}px) {
    max-width: 970px;
    margin: auto;
  }
  @media(min-width: ${XL}px) {
    max-width: 1170px;
    // max-width: 1200px;
    margin: auto;
  }
`

export const NavigationContainer = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  @media(max-width: 600px) {
  padding: 0;    
  }
  @media(min-width: ${M}px) and (max-width:${L}px) {
    max-width: 728px;
    margin: auto;
  }
  @media(min-width: ${L}px) and (max-width:${XL}px) {
    max-width: 964px;
    margin: auto;
  }
  @media(min-width: ${XL}px) {
    max-width: 1200px;
    margin: auto;
  }
`

export const HeaderContainer = styled.div `
  z-index: 2;
//  padding: 25px;
//  padding-top: 0;
  background-color: ${props => props.sticky
  ? 'transparent'
  : '#fafafa'};
  
  @media(max-width: 600px) {
    height: 100px;
    form: {
      margin-top: 20px;
    }
    padding: 5px;
    padding-top: 9px;
  }
  
@media(max-width: ${XXS}px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  @media(max-width: ${XS}px) {
    padding-left: 8px;
    padding-right: 8px;
  }
  @media(max-width: ${M}px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media(min-width: ${M}px) and (max-width:${L}px) {
//    max-width: 752px;
    margin: auto;
  }
  @media(min-width: ${L}px) and (max-width:${XL}px) {
//    max-width: 1020px;
    margin: auto;
  }
  @media(min-width: ${XL}px) {
//    max-width: 1260px;
    margin: auto;
  }
`
