import React from 'react'

import { SpinnerCubeContainer, Cube1, Cube2, Cube3, Cube4 } from './Spinner.style';

const spinner = () => {
  return (
    <div style={{position: 'fixed', top: 'calc((100vh / 2) - 30px)', left: 'calc((100vw / 2) - 30px)'}}>
    <SpinnerCubeContainer>
      <Cube1 />
      <Cube2 />
      <Cube4 />
      <Cube3 />
    </SpinnerCubeContainer>
    </div>
  )
}

export default spinner
