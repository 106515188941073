import React from 'react';
import {Link} from 'react-router-dom';
import {Fade} from 'react-reveal';
import {AppConsumer} from '../API/context'
import {scrollToTop} from '../API/utils'
import SectionTitle from '../components/SectionTitle'

const HomeBrands = () => <AppConsumer> 
{
  ({brandsData}) => {
    const perChunk = window.innerWidth > 480 ? 4 : 2;
    const transformedBrands = brandsData.reduce((resultArray, item, index) => { 
      const chunkIndex = Math.floor(index/perChunk)

      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
    {/* console.log('[transformedBrands]', transformedBrands) */}
    
    return <div>
    {brandsData && brandsData.length ? <Fade><SectionTitle title='Our Products' /></Fade> : null}
      {
        window.innerWidth > 480 ? transformedBrands.map((brands, index) => <div style={{display:'flex', flexDirection:'row', justifyContent:`${brands.length === 4 ? 'space-between' : 'space-around'}`, alignItems:'center', marginTop:`${index === 0 ? '20px' : 'auto'}`, height:`${window.innerWidth > 480 ? '160px' : 'auto'}`, overflow:'hidden'}}>
          {brands.map(({slug, brandImage}, index) => <Fade delay={index * 100}><Link onClick={scrollToTop} to={`products/${slug}`}><img className='img-logo-landing' src={brandImage.fields.file.url} alt={slug}/></Link></Fade>)}
        </div>) : transformedBrands.map(brands => <div style={{display:'flex', flexDirection:'row', justifyContent:`${brands.length === 2 ? 'space-between' : 'space-around'}`, alignItems:'center', height:`${window.innerWidth > 480 ? '160px' : 'auto'}`, overflow:`${brands.length === 1 ? 'show' : 'hidden'}`}}>
          {brands.map(({slug, brandImage}, index) => <Fade delay={index * 100}><Link onClick={scrollToTop} to={`products/${slug}`}><img style={{marginTop:`${brands.length === 1 ? '-20%' : 'auto'}`, width:`${brands.length === 1 ? '50%' : '75%'}`}} className='img-logo-landing' src={brandImage.fields.file.url} alt={slug}/></Link></Fade>)}
        </div>)
      }
    </div>}
  }
</AppConsumer>

  export default HomeBrands;