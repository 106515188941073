import React, {Fragment} from 'react';
import {scrollToTop} from '../API/utils'
import {Link} from 'react-router-dom'

const BreadCrumb = ({breadcrumbs, goTo = ()=>alert('clicked')}) => <div style={{textAlign:'left', marginLeft:`${window.innerWidth > 480 ? '0' : '0px'}`}}>
  <Link onClick={scrollToTop} to={'/'} style={{cursor: 'pointer', color:'grey', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>Home</Link> 
  {breadcrumbs.map((breadcrumb, index) => <Fragment>
    <span> / </span>
    {
      index !== breadcrumbs.length -1 ?
      <Link onClick={scrollToTop} to={'/' + breadcrumb} style={{color:'grey', cursor:'pointer', textTransform:'capitalize', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>{breadcrumb}</Link>
      :
      <span style={{color:'#777', cursor:'pointer', fontWeight:'bold', textTransform:'capitalize', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>{breadcrumb}</span>
    }
  </Fragment>)}
</div>

export default BreadCrumb;