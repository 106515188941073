import React, {Component, Fragment} from 'react';
import {MainContainer} from '../theme/style'
import SectionTitle from './SectionTitle'
import {getSocialMedia} from '../API/contentful'

class Footer extends Component {
  state = {
    socialMedia: []
  }
  async componentDidMount() {
    this.setState({
      socialMedia: await getSocialMedia()
    })
  }

  render() {
    console.log(this.state)
    let socialMedia = <span></span>
    if(this.state.socialMedia.length) {
      socialMedia = this.state.socialMedia.map(sosmed => <a href={sosmed.fields.url} target='_blank'><img src={sosmed.fields.image.fields.file.url} style={{width: "40px", height: "40px", padding: "5px", paddingBottom: "0px"}} /></a>)
    } 

    return(<MainContainer style={{paddingBottom:'30px'}}>
    <div className='social-media-container' style={{justifyContent:`${window.innerWidth > 480 ? 'flex-start' : 'center'}`, paddingBottom:'10px', paddingTop:'30px'}}>
      {socialMedia}
      {/* <a href="https://www.instagram.com/pt.smartmitrasolutions" target='_blank'><i class="social-media fab fa-instagram"></i></a>
      <a href="https://www.linkedin.com/company/smartmitra/" target='_blank'><i class="social-media fab fa-linkedin"></i></a>
      <a href={`http://api.whatsapp.com/send?phone=628119177772&text=${"Halo SMS Admin, saya ingin bertanya mengenai produk SMS"}`} target='_blank'><i class="social-media fab fa-whatsapp"></i></a> */}
      {/* <i class="social-media fab fa-youtube"></i> */}
    </div>
    <SectionTitle noTitle />
    <h6 style={{margin:'0', color:'grey', fontWeight:'normal', marginBottom:'10px', fontSize:'14px', textAlign:`${window.innerWidth >480 ? 'left' : 'center'}`}}>PT Smart Mitra Solutions © 2020</h6>
  </MainContainer>);
  }
}

export default Footer;