import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {getCategoriesProduct} from '../API/contentful'
import jc from 'json-cycle'
import {Link} from 'react-router-dom'
import ReactMarkdown from 'react-markdown';
import {scrollToTop} from '../API/utils'
import {MainContainer} from '../theme/style'
import {Fade} from 'react-reveal';
import Spinner from './Spinner'
import Metadata from '../components/Metadata'

const BreadCrumbCategoriesLanding = ({brand, categoryName}) => <div style={{textAlign:'left', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>
  <Link onClick={scrollToTop} to={'/'} style={{cursor: 'pointer', color:'grey'}}>Home</Link> 
    <span> / </span>
    <Link onClick={scrollToTop} to={'/products'} style={{color:'grey', cursor:'pointer', textTransform:'capitalize'}}>Products</Link>
    <span> / </span>
    <Link onClick={scrollToTop} to={'/products/' + brand.slug} style={{color:'grey', cursor:'pointer', textTransform:'capitalize'}}>{brand.name}</Link>
    <span> / </span>
    <Link onClick={scrollToTop} to={'/products/' + categoryName} style={{color:'grey', cursor:'pointer', textTransform:'capitalize', textDecoration: 'none', fontWeight:'bold'}}>{categoryName}</Link>
</div>

class CategoriesLandingPage extends Component {
  state = {
    products: JSON.parse(localStorage.getItem(this.props.location.pathname.split('/')[3])) && JSON.parse(localStorage.getItem(this.props.location.pathname.split('/')[3])).products || null,
    urls: [],
    collectionName: JSON.parse(localStorage.getItem(this.props.location.pathname.split('/')[3])) && JSON.parse(localStorage.getItem(this.props.location.pathname.split('/')[3])).collectionName || null,
    brand: JSON.parse(localStorage.getItem(this.props.location.pathname.split('/')[3])) && JSON.parse(localStorage.getItem(this.props.location.pathname.split('/')[3])).brand || null,
    intro: JSON.parse(localStorage.getItem(this.props.location.pathname.split('/')[3])) && JSON.parse(localStorage.getItem(this.props.location.pathname.split('/')[3])).intro || null
  }
  
  async componentDidMount() {
    const categoryName = this.props.location.pathname.split('/')[3];
    // const categoriesName = await getCategoriesName(categoryName);
    const categoriesProducts = await getCategoriesProduct(categoryName);
    const products = categoriesProducts.products;
    const collectionName = categoriesProducts.title;
    const brand = categoriesProducts.brand;
    const intro = categoriesProducts.intro;
    this.setState({products, collectionName, brand, intro}, () => {
      localStorage.setItem(categoryName, JSON.stringify(jc.decycle({products, collectionName, brand, intro})));
    });
  }

  _renderBannerBreadCrumb = () => {
    const {collectionName, brand} = this.state;
    if (collectionName, brand) {
      return <Fragment>
        <BreadCrumbCategoriesLanding brand={brand} categoryName={collectionName} />
        <Metadata title={collectionName + ' ' + brand.name} description='We are the authorized distributor of the leading brands' />
        </Fragment>
    }
  }
  
  _trimName = (name) => {
    return name.replace('product', '').replace('Product', '')
                .replace('products', '').replace('Products', '')
  }
  
  
  render() {
    const {products, intro, collectionName} = this.state;
    const renderBannerBreadCrumb = this._renderBannerBreadCrumb();
    
    let renderProducts = null;
    if (products && products.length) {
      renderProducts = products.map(({fields}, index) => {
        return (<Fade><div style={{marginBottom:`${window.innerWidth > 480 ? '50px' : '25px'}`, paddingBottom:`${window.innerWidth > 480 ? '50px' : '25px'}`, borderBottom:`${products.length -1 === index ? 'none' : '1px solid gainsboro'}`}}>
            <h1 style={{textTransform: 'uppercase', fontWeight:'normal', textAlign:'left', color:'#777', fontSize:`${window.innerWidth > 480 ? '24px' : '16px'}`}}>{fields.productName}</h1>
            <div style={{display:'flex', height:`${window.innerWidth <= 480 ? 'auto' : '300px'}`, flexDirection:`${window.innerWidth > 480 ? index % 2 === 0 ? 'row' : 'row-reverse' : 'column'}`}}>
            <div style={{backgroundImage:`url(${fields.productPhoto.fields.file.url})`, width:'100%', height:`${window.innerWidth > 480 ? 'auto' : '300px'}`, backgroundPosition:'center', backgroundSize:'contain', backgroundRepeat:'no-repeat'}}></div>
            <div style={{width:'100%'}}>
            {fields.description && fields.productPhoto.fields.file.url && <ReactMarkdown className='readable-text product-detail-title' escapeHtml={false} skipHtml={false} source={fields.description.content[0].content[0].value} />}
            {fields.source && fields.source.length ? <div style={{marginTop:'10px'}}>
            {
              fields.source.map((source, index) => <div key={index} style={{textAlign:'left'}}>
                <a href={source} target='_blank' style={{textDecoration:'none', color:'#24326D'}}>
                <i class="fas fa-chevron-right"></i> 
                <span style={{marginLeft:'10px', fontSize:`${window.innerWidth > 480 ? 'auto' : '14px'}`}}>Learn More</span>  
                </a>
              </div>)
            }
            </div> : null}
            </div>
          </div>
        </div></Fade>)
      })
    }
    
    return (<MainContainer style={{padding:'30px 10px'}}>
      {renderBannerBreadCrumb}
      {collectionName && <div style={{marginTop:'50px', fontSize:`${window.innerWidth > 480 ? '32px' : '16px'}`, textTransform:'uppercase', textDecoration:`${window.innerWidth > 480 ? 'none' : 'underline'}`, color:"#777", marginBottom:`${intro ? '10px' : window.innerWidth > 480 ? '75px' : '50px'}`}}>{this._trimName(collectionName)} Products</div>}
      {intro && <ReactMarkdown className='product-title' escapeHtml={false} skipHtml={false} source={intro} /> }
      {renderProducts}
      {!this.state.products && <Spinner />}
    </MainContainer>);
  }
}

export default withRouter(CategoriesLandingPage);