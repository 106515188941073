import React, {Component, Fragment} from 'react'
import {MainContainer} from '../theme/style'
import smsLogo from '../assets/sms-logo.gif'
import { slide as Menu } from 'react-burger-menu'
import {NavLink, Link, withRouter} from 'react-router-dom';
import {scrollToTop} from '../API/utils'
// http://curative-rate.000webhostapp.com/form-to-email.php
class Header extends Component {
  state = {
    isOpen: false
  }

 isMenuOpen = (state) => {
    return state.isOpen;
  };
  componentDidMount () {
    // var burger = document.getElementsByClassName("bm-burger-button")[0];
    // burger.onclick = () => this.setState({
    //   isOpen: true
    // })
  }


  render() {
      let styles = {
        bmBurgerButton: {
          position: 'fixed',
          width: '36px',
          height: '30px',
          left: '20px',
          top: '15px'
        },
        bmBurgerBars: {
          background: 'grey'
        },
        bmBurgerBarsHover: {
          background: '#a90000'
        },
        // bmCrossButton: {
        //   height: '24px',
        //   width: '24px',
        //   right: '25px',
        //   top: '20px',
        // },
        // bmCross: {
        //   background: '#bdc3c7',
        //   height: '30px',
        //   zIndex: 999
        // },
        bmMenuWrap: {
          position: 'fixed',
          height: '100%',
          width: '70vw',
          top: 0,
          left: 0,
        },
        bmMenu: {
          background: 'white',
          fontSize: '1.15em',
          top: 0
        },
        bmMorphShape: {
          fill: '#373a47'
        },
        bmItemList: {
          color: '#b8b7ad',
          padding: '0.8em',
          display: 'flex',
          flexDirection: 'column',
          boxSizing:'border-box '
        },
        bmItem: {
          display: 'block',
          padding:'10px 0',
          borderBottom:'1px solid gainsboro',
          width:'100%',
          textAlign:'left',
          fontSize: '16px',
          margin: 0,
          color:'#777',
          textDecoration: 'none'
        },
        bmOverlay: {
          background: 'rgba(0, 0, 0, 0.37)',
          width: '110%'
        }
      }
      
      const customCollectionRule = this.props.location.pathname.includes('/collection/') || this.props.location.pathname.includes('/products');
      
      return (
        <Fragment>
        {window.innerWidth >= 480 ? <MainContainer>
        <navigation style={{display:'flex', justifyContent:'center', alignItems:'center', 
        width: "100%",
        background:"white",
        position:"fixed", 
        zIndex:"999",
        top:"0", 
        left:"0", boxShadow: '0px -2px 5px 0px'}}>
        <MainContainer>      
          <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'10px 0', height:'40px'}}>
            <Link onClick={scrollToTop} activeClass="active" style={{display:'flex', position: 'absolute'}} to="/">
              <img src={smsLogo} alt="sms" style={{height:'40px'}} />
            </Link>
            <div style={{width:'100%'}}>
              <NavLink onClick={scrollToTop} exact className="header-navigation-link" activeClassName='active-link' to="/"><span>Home</span></NavLink>
              <NavLink onClick={scrollToTop} style={{fontWeight:`${customCollectionRule ? 'bold' : 'normal'}`}} className="header-navigation-link" activeClassName='active-link' to={"/products"}><span>Products</span></NavLink>
              <NavLink onClick={scrollToTop} className="header-navigation-link" activeClassName='active-link' to="/services"><span>Services</span></NavLink>
              <NavLink onClick={scrollToTop} className="header-navigation-link" activeClassName='active-link' to="/news-and-events"><span>News and Events</span></NavLink>
              <NavLink onClick={scrollToTop} exact className="header-navigation-link" activeClassName='active-link' to="/about"><span>About Us</span></NavLink>
              <NavLink onClick={scrollToTop} exact className="header-navigation-link" activeClassName='active-link' to="/contact"><span>Contact Us</span></NavLink>
            </div>
            <div>
            </div>
          </div>
        </MainContainer>
      </navigation>  
      </MainContainer> : <Fragment> 
          <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginLeft: '-20px'}}>
            <Menu isOpen={this.state.isOpen}
            disableOverlayClick
             styles={ styles } 
            customBurgerIcon={ false }
            >
              <div className='header-mobile' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Link onClick={scrollToTop} to='/'><img src={smsLogo} alt="sms" style={{height:'40px'}} /></Link>
                <i class="fas fa-times" style={{color:'#777', fontSize:'35px'}} onClick={() => this.setState({isOpen: false})}></i>
              </div>
              <NavLink onClick={scrollToTop} exact activeStyle={{fontWeight:'bold'}} onClick={() => this.setState({isOpen: false})} to="/"><span>Home</span></NavLink>
              <NavLink onClick={scrollToTop} activeStyle={{fontWeight:'bold'}} onClick={() => this.setState({isOpen: false})} to="/products"><span>Products</span></NavLink>
              <NavLink onClick={scrollToTop} activeStyle={{fontWeight:'bold'}} onClick={() => this.setState({isOpen: false})} to="/services"><span>Services</span></NavLink>
              <NavLink onClick={scrollToTop} activeStyle={{fontWeight:'bold'}} onClick={() => this.setState({isOpen: false})} to="/news-and-events"><span>News And Events</span></NavLink>
              <NavLink onClick={scrollToTop} exact activeStyle={{fontWeight:'bold'}} onClick={() => this.setState({isOpen: false})} to="/about"><span>About Us</span></NavLink>
              <NavLink onClick={scrollToTop} exact activeStyle={{fontWeight:'bold'}} onClick={() => this.setState({isOpen: false})} to="/contact"><span>Contact Us</span></NavLink>
            </Menu>
          </div>
          <i class="fas fa-bars" style={{color:'#777',position:'fixed', zIndex: 3, left:'20px', top:'12px', fontSize:'35px'}} onClick={() => this.setState({isOpen: true})}></i>
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'60px', backgroundColor:'white', position:'fixed', width:'100vw', zIndex: 2, boxShadow: '0px -2px 5px 0px'}}>
            <img src={smsLogo} alt="sms" style={{height:'40px'}} />
          </div>
          </Fragment> }
        </Fragment>
)
  }
}

export default withRouter(Header)
